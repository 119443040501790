import { css } from '@emotion/react';
import { Checkbox } from '@mantine/core';
import { ReportingBias } from '__generated__/graphql';
import { ChartData } from 'chart.js';
import { useState } from 'react';
import { Bar } from 'react-chartjs-2';

interface Props {
    user: ReportingBias;
    cohort: ReportingBias;
}

export function HorizontalBarChart(props: Props) {
    const { user, cohort } = props;
    const [showTrueNegative, setShowTrueNegative] = useState<boolean>(false);

    const labels = () => {
        const l = ['True Positive', 'False Positive', 'False Negative'];

        if (showTrueNegative) {
            l.push('True Negative');
        }

        return l;
    };

    const userData = () => {
        const d = [user.truePositive, user.falsePositive, user.falseNegative];

        if (showTrueNegative) {
            d.push(user.trueNegative);
        }

        return d;
    };

    const cohortData = () => {
        const d = [cohort.truePositive, cohort.falsePositive, cohort.falseNegative];

        if (showTrueNegative) {
            d.push(cohort.trueNegative);
        }

        return d;
    };

    const chartData: ChartData<'bar'> = {
        labels: labels(),
        datasets: [
            {
                label: 'You',
                data: userData(),
                backgroundColor: 'rgba(255,99,132,0.2)',
                borderColor: 'rgba(255,99,132,1)'
            },
            {
                label: 'Cohort',
                data: cohortData(),
                backgroundColor: 'rgba(179,181,198,0.2)',
                borderColor: 'rgba(179,181,198,1)'
            }
        ]
    };

    return (
        <div
            css={css`
                flex: 1;
            `}
        >
            <Checkbox
                label="Show True Negative?"
                checked={showTrueNegative}
                onChange={(ev) => setShowTrueNegative(ev.target.checked)}
                css={css`
                    margin-bottom: 1rem;
                `}
            />
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    // indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2
                        }
                    },
                    plugins: {
                        legend: {
                            position: 'bottom'
                        },
                        tooltip: {
                            callbacks: {
                                label(tooltipItem) {
                                    return `${Number(tooltipItem.raw).toFixed(1)}%`;
                                }
                            }
                        }
                    }
                }}
            />
        </div>
    );
}
