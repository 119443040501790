import { css } from '@emotion/react';
import Close from '@mui/icons-material/Close';

export function IncorrectCross() {
    return (
        <div
            css={css`
                color: #bd1212;
                display: flex;
                align-items: center;
                gap: 2px;
                margin-left: 4px;
                font-weight: 600;
            `}
        >
            <Close />
            incorrect
        </div>
    );
}
