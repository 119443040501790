import { css } from '@emotion/react';
import Check from '@mui/icons-material/Check';

export function CorrectCheck() {
    return (
        <div
            css={css`
                color: #0f9703;
                display: flex;
                align-items: center;
                gap: 2px;
                margin-left: 4px;
                font-weight: 600;
            `}
        >
            <Check />
            correct
        </div>
    );
}
