import { Chart } from 'chart.js';
import { addMonths, subMonths, format } from 'date-fns';
import { useEffect, useRef } from 'react';

interface Props {
    data: number[];
}

export function LineChart(props: Props) {
    const { data } = props;
    const ref = useRef<HTMLCanvasElement>(null);

    const labels = () => {
        const start = subMonths(new Date(), 1);

        const ls = new Array(6).fill(0).map((_, idx) => format(addMonths(start, idx + 1), 'MMMM'));

        return [format(start, 'MMMM'), ...ls];
    };

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const chart = new Chart(ref.current, {
            type: 'line',
            data: {
                labels: labels(),
                datasets: [
                    {
                        label: 'Your Ranking (higher is better)',
                        fill: false,
                        // lineTension: 0.1,
                        backgroundColor: 'rgba(75,192,192,0.4)',
                        borderColor: 'rgba(75,192,192,1)',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBorderColor: 'rgba(75,192,192,1)',
                        pointBackgroundColor: '#fff',
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                        pointHoverBorderWidth: 2,
                        pointRadius: 1,
                        pointHitRadius: 10,
                        data: [data[0], ...data]
                    }
                ]
            },
            options: {
                responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                        max: 100
                    }
                }
            }
        });

        return () => {
            chart.destroy();
        };
    }, [data]);

    return <canvas ref={ref} />;
}
