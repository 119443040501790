import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

interface Props {
    data: number[];
}

export function BarChart(props: Props) {
    const { data } = props;

    const chartData: ChartData<'bar'> = {
        labels: ['0/4', '1/4', '2/4', '3/4', '4/4'],
        datasets: [
            {
                label: 'Correct answers per case',
                backgroundColor: 'rgba(0,132,255,0.2)',
                borderColor: 'rgba(0,132,255,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,132,255,0.2)',
                hoverBorderColor: 'rgba(0,132,255,1)',
                data
            }
        ]
    };

    return <Bar data={chartData} options={{ responsive: true }} />;
}
