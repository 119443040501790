import { css } from '@emotion/react';

export const OtherContainer = ({ children, _css }: { children: React.ReactNode; _css?: ReturnType<typeof css> }) => {
    return (
        <div
            css={css`
                display: flex;
                gap: 0.5rem;
                ${_css}
            `}
        >
            {children}
        </div>
    );
};
