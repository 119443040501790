import React, { useEffect } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useFormContext } from 'react-hook-form';
import { MultiChocieAnswer, MultiChoiceQuestion } from '__generated__/graphql';
import { Correction } from './correction';
import { QuestionHeading } from './question-heading';
import { css } from '@emotion/react';
import { FormUtils } from './form-utils';

interface Props {
    question: MultiChoiceQuestion;
    showCorrect?: boolean;
    overrideSelection?: MultiChocieAnswer['id'];
}

export default function RadioQuestion(props: Props) {
    const { question, showCorrect, overrideSelection } = props;
    const { id, text, possibleAnswers } = question;
    const methods = useFormContext();
    const { register, setValue, watch, formState, getFieldState, trigger } = methods;
    const disabled = formState.isSubmitting || showCorrect;

    const questionId = `MultiChoiceQuestion.q-${id}`;

    console.log('radio showing correct', showCorrect, questionId, question);

    useEffect(() => {
        FormUtils.registerQuestion({
            questionId,
            questionType: 'MultiChoiceQuestion',
            register
        });
    }, [questionId, register]);

    useEffect(() => {
        if (!!overrideSelection) {
            setValue(`${questionId}.answer`, overrideSelection);
        }
    }, [overrideSelection, questionId, setValue]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
        console.log(`Setting [${questionId}] to [${value}]`);

        setValue(questionId, {
            questionType: 'MultiChoiceQuestion',
            answer: value
        });
        trigger(questionId);
    };

    const w = watch(questionId);
    const watchValue = Number(w?.answer) || -1;
    const correctAnswers = possibleAnswers?.filter((a) => !!a?.isCorrect);

    return (
        <div>
            <QuestionHeading
                showCorrect={showCorrect}
                isCorrect={
                    !!correctAnswers?.find(
                        (a) => !!a?.id && (Array.isArray(watchValue) ? watchValue.includes(a.id) : a.id === watchValue)
                    )
                }
                error={!!getFieldState(questionId).error}
                errorText={FormUtils.getErrorMessage({ questionId, getFieldState })}
            >
                {text}
            </QuestionHeading>
            <RadioGroup
                css={css`
                    flex-direction: row;
                `}
                onChange={onChange}
                value={watchValue}
            >
                {(possibleAnswers as MultiChocieAnswer[]).map((ans: MultiChocieAnswer) => (
                    <FormControlLabel
                        disabled={disabled}
                        key={ans.id}
                        value={ans.id}
                        name={String(ans.id)}
                        control={<Radio checked={watchValue === ans.id} disabled={disabled} />}
                        label={ans.text}
                        css={css`
                            margin-right: 3rem;
                        `}
                    />
                ))}
            </RadioGroup>
        </div>
    );
}
