import 'index.css';
import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import { Router } from 'react-router';
import history from 'config/history';
import CssBaseLine from '@mui/material/CssBaseline';
import App from './layouts/App';
import theme from 'config/mui-theme';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createClient } from '@supabase/supabase-js';
import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { loadInspectlet } from 'config/load-inspectlet';
import { rudderAnalytics } from 'utils/analytics';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

loadInspectlet();
rudderAnalytics.load(
    import.meta.env.VITE_APP_RUDDERSTACK_WRITE_KEY,
    import.meta.env.VITE_APP_RUDDERSTACK_DATA_PLANE_URL,
    {}
);

const posthogEnabled = window.location.host !== 'localhost:5173';
console.log({ posthogEnabled });

const options: Partial<PostHogConfig> = {
    autocapture: posthogEnabled,
    disable_session_recording: !posthogEnabled,
    ui_host: 'https://us.i.posthog.com',
    api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_PROXY
};

export const supabase = createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_PUBLIC_KEY);

const authLink = setContext(async (_, { headers }) => {
    const token = (await supabase.auth.getSession()).data.session?.access_token;

    return {
        headers: {
            ...headers,
            ...(token ? { authorization: `Bearer ${token}` } : null)
        }
    };
});

const httpLink = createHttpLink({
    uri: import.meta.env.VITE_APP_GRAPHQL_API_URL
});

const errorLink = onError((args) => {
    console.log('errorLink', args);
    const { graphQLErrors } = args;

    if (graphQLErrors) {
        for (const err of graphQLErrors) {
            const { extensions } = err;
            // @ts-expect-error idk
            const { code } = extensions;

            if (code === 'UNAUTHORIZED') {
                supabase.auth.signOut();
            }
        }
    }
});

const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <MantineProvider>
                <Router history={history}>
                    <ApolloProvider client={client}>
                        <PostHogProvider apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY} options={options}>
                            <CssBaseLine />
                            <App />
                        </PostHogProvider>
                    </ApolloProvider>
                </Router>
            </MantineProvider>
        </ThemeProvider>
    </React.StrictMode>
);
