import { css } from '@emotion/react';
import { Button } from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

type Props = ReturnType<typeof Button>['props'];

function _SubmitButton(props: Props, ref: ForwardedRef<HTMLButtonElement>) {
    return (
        <Button
            {...props}
            ref={ref}
            color="#ad132d"
            css={css`
                /* background-color: ; */
                /* color: white; */
                font-weight: 500;
                border-radius: 0.2em;
                /* border: none; */
                height: 3em;

                /* &:hover {
                    background-color: #ad132d;
                    cursor: pointer;
                } */
            `}
        />
    );
}

export const SubmitButton = forwardRef<HTMLButtonElement, Props>(_SubmitButton);
