import { css } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, ComboboxItem, Group, LoadingOverlay, Radio, TextInput, Text, Divider } from '@mantine/core';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { CmeSelect } from './select';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SELF } from 'graphql/queries';
import { SET_CME_REGISTRATION } from 'graphql/mutations';

const requireManualIfOther = (val: { preset: string; manual?: string }) => {
    if (val.preset !== 'other') {
        return true;
    }

    return !!val.manual;
};

const schema = z.object({
    register: z.boolean(),
    college: z
        .object({
            preset: z.string().min(1, 'Please select a college'),
            manual: z.string().optional()
        })
        .default({ preset: '', manual: '' })
        .refine(requireManualIfOther, {
            message: 'College is required',
            path: ['manual']
        }),
    collegeId: z.string().min(1, 'Please enter College ID'),
    careerStage: z
        .object({
            preset: z.string().min(1, 'Please select a career stage'),
            manual: z.string().optional()
        })
        .default({ preset: '', manual: '' })
        .refine(requireManualIfOther, { message: 'Career Stage is required', path: ['manual'] }),
    specialty: z
        .object({
            preset: z.string().min(1, 'Please select a specialty'),
            manual: z.string().optional()
        })
        .default({ preset: '', manual: '' })
        .refine(requireManualIfOther, { message: 'Specialty is required', path: ['manual'] })
});

export type Schema = z.infer<typeof schema>;

const collegeOptions: ComboboxItem[] = [
    { label: 'CSANZ - Cardiac Society of Australia and New Zealand', value: 'csanz' },
    {
        label: 'ANZCTCA - Conjoint Committee for the Recognition of Training in CT Coronary Angiography',
        value: 'anzctca'
    },
    { label: 'RANZCR - Royal Australian and New Zealand College of Radiologists', value: 'ranzcr' },
    { label: 'RACS - Royal Australasian College of Surgeons', value: 'racs' },
    { label: 'Other', value: 'other' }
];

const careerStageOptions: ComboboxItem[] = [
    { label: 'Specialist', value: 'specialist' },
    { label: 'Fellow', value: 'fellow' },
    { label: 'Trainee', value: 'trainee' },
    { label: 'Junior Doctor', value: 'junior-doctor' },
    { label: 'Non Medical', value: 'non-medical' },
    { label: 'Other', value: 'other' }
];

const specialtyOptions: ComboboxItem[] = [
    { label: 'Cardiology', value: 'cardiology' },
    { label: 'Radiology', value: 'radiology' },
    { label: 'Other', value: 'other' }
];

const OtherContainer = ({ children }: React.PropsWithChildren) => {
    return (
        <div
            css={css`
                display: flex;
                gap: 0.5rem;
            `}
        >
            {children}
        </div>
    );
};

const presetOrManualIfOther = (val: { preset: string; manual?: string }) => {
    if (val.preset !== 'other') {
        return val.preset;
    }

    return val.manual;
};
export function UserProfileCme() {
    const methods = useForm<Schema>({
        resolver: zodResolver(schema),
        reValidateMode: 'onChange'
    });
    const { register, watch, setValue, handleSubmit, formState } = methods;
    const { data } = useQuery(GET_SELF, { fetchPolicy: 'network-only' });
    const [registerCme] = useMutation(SET_CME_REGISTRATION);

    useEffect(() => {
        register('register');
    }, [register]);

    useEffect(() => {
        const cme = data?.me?.cme;
        if (cme) {
            const { register, college, collegeId, careerStage, specialty } = cme;
            setValue('register', register);
            setValue('collegeId', collegeId ?? '');

            const presetCollege = collegeOptions.find((c) => c.value === college)?.value;
            setValue(
                'college',
                presetCollege ? { preset: presetCollege, manual: '' } : { preset: '', manual: college ?? '' }
            );

            const presetCareer = careerStageOptions.find((c) => c.value === careerStage)?.value;
            setValue(
                'careerStage',
                presetCareer ? { preset: presetCareer, manual: '' } : { preset: '', manual: careerStage ?? '' }
            );

            const presetSpecialty = specialtyOptions.find((c) => c.value === specialty)?.value;
            setValue(
                'specialty',
                presetSpecialty ? { preset: presetSpecialty, manual: '' } : { preset: '', manual: specialty ?? '' }
            );
        }
    }, [data?.me?.cme, setValue]);

    console.log(watch());

    const reg = watch('register');
    console.log('reg', reg);

    const disabled = !watch('register');

    const onSubmit = async (formData: Schema) => {
        console.log(formData);

        const { register, college, collegeId, careerStage, specialty } = formData;

        const res = await registerCme({
            variables: {
                input: {
                    register,
                    college: presetOrManualIfOther(college),
                    collegeId,
                    careerStage: presetOrManualIfOther(careerStage),
                    specialty: presetOrManualIfOther(specialty)
                }
            }
        });

        console.log('res', res);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <LoadingOverlay visible={formState.isSubmitting} />
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        width: 30rem;
                        gap: 0.75rem;
                    `}
                >
                    <Text size="lg" mb="lg">
                        CME Registration
                        <Divider />
                    </Text>
                    <Radio.Group
                        value={watch('register') ? 'yes' : 'no'}
                        onChange={(val) => setValue('register', val === 'yes')}
                        label="Register for CME points?"
                    >
                        <Group gap="lg">
                            <Radio value="yes" label="Yes" />
                            <Radio value="no" label="No" />
                        </Group>
                    </Radio.Group>
                    <OtherContainer>
                        <CmeSelect
                            fieldName="college"
                            label="College"
                            placeholder="Select College"
                            data={collegeOptions}
                            disabled={disabled}
                            withAsterisk
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <TextInput
                            {...register('collegeId')}
                            label="College ID"
                            placeholder="ABC-123"
                            disabled={disabled}
                            error={formState.errors.collegeId?.message}
                            w="100%"
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <CmeSelect
                            fieldName="careerStage"
                            label="Career Stage"
                            placeholder="Select Career Stage"
                            data={careerStageOptions}
                            disabled={disabled}
                            withAsterisk
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <CmeSelect
                            fieldName="specialty"
                            label="Specialty"
                            placeholder="Select Specialty"
                            data={specialtyOptions}
                            disabled={disabled}
                            withAsterisk
                        />
                    </OtherContainer>
                </div>
                <Button
                    css={css`
                        margin-top: 1.5rem;
                    `}
                    type="submit"
                >
                    Update CME Registration
                </Button>
            </form>
        </FormProvider>
    );
}
