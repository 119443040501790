import { css } from '@emotion/react';
import { Divider } from '@mantine/core';

interface Props {
    children: React.ReactNode;
}

export function Correction(props: Props) {
    const { children } = props;

    return (
        <div
            css={css`
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            `}
        >
            <Divider />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;
                `}
            >
                {children}
            </div>
        </div>
    );
}
