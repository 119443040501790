import { Divider, Paper, Text, Table, LoadingOverlay } from '@mantine/core';
import { UserProfileCme } from './components/CME';
import { UserProfileSubscriptions } from './components/Subscriptions';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { GET_SELF } from 'graphql/queries';
import { jurisdictions, organisations, specialtyOptions } from './data';

const Tab = ({ children }: React.PropsWithChildren) => {
    return (
        <Paper
            shadow="xs"
            p="xl"
            withBorder
            css={css`
                min-width: 30rem;
            `}
        >
            {children}
        </Paper>
    );
};

export default function UserProfilePage() {
    const { data, loading } = useQuery(GET_SELF, {
        fetchPolicy: 'network-only'
    });

    return (
        <div
            css={css`
                display: flex;
                /* grid-template-columns: 1fr 1fr;
                grid-auto-rows: min-content; */
                gap: 3rem;
                padding: 3rem;
                flex-wrap: wrap;
            `}
        >
            <LoadingOverlay visible={loading} />
            <Tab>
                <Text size="lg" mb="lg">
                    Account Information
                    <Divider />
                </Text>
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        width: 100%;
                        min-width: 20rem;
                    `}
                >
                    <Table w="100%">
                        <Table.Tr>
                            <Table.Td>Name</Table.Td>
                            <Table.Td>
                                {data?.me?.firstName} {data?.me?.lastName}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>Email Address</Table.Td>
                            <Table.Td>{data?.me?.email}</Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>Organisation</Table.Td>
                            <Table.Td>
                                {organisations.find((o) => o.value === data?.me?.organisation)?.label ?? 'None'}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>Accreditation Jurisdiction</Table.Td>
                            <Table.Td>
                                {jurisdictions.find((o) => o.value === data?.me?.accreditationJurisdiction)?.label ??
                                    'None'}
                            </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                            <Table.Td>Specialty</Table.Td>
                            <Table.Td>
                                {specialtyOptions.find((o) => o.value === data?.me?.specialty)?.label ?? 'None'}
                            </Table.Td>
                        </Table.Tr>
                    </Table>
                    {/* <Button
                        css={css`
                            margin-top: 1rem;
                            width: 12rem;
                        `}
                    >
                        Change Password
                    </Button> */}
                </div>
            </Tab>
            <Tab>
                <UserProfileCme />
            </Tab>
            <Tab>
                <UserProfileSubscriptions />
            </Tab>
        </div>
    );
}
