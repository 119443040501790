import { css } from '@emotion/react';
import { Button, Divider, Tabs } from '@mantine/core';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { StudyReview } from '__generated__/graphql';
import DOMPurify from 'dompurify';
import { InnerTabPanel } from '.';
import { StudyUtils } from 'utils/study-utils';
import { useMemo } from 'react';

interface Props {
    review: StudyReview;
    token: string;
    onBackToStudy: () => void;
    topRightContent?: React.ReactNode;
}

export function DumbCaseReviewPage(props: Props) {
    const { review, token, onBackToStudy, topRightContent } = props;

    const sortedAreasOfInterest = useMemo(() => {
        const order = [
            'LMA',
            'LAD_Prox',
            'LAD_Mid',
            'LAD_Dist',
            'D1',
            'D2',
            'D3',
            'LCX',
            'OM1',
            'OM2',
            'RCA_Prox',
            'RCA_Mid',
            'RCA_Dist',
            'PDA',
            'PLB',
            'RCA_Rm'
        ];

        return [...(review?.areasOfInterest ?? [])].sort(
            (a, b) => order.indexOf(a.segmentName) - order.indexOf(b.segmentName)
        );
    }, [review?.areasOfInterest]);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
            `}
        >
            <div
                css={css`
                    display: flex;
                    gap: 3rem;
                    padding: 0 4rem;
                    text-align: center;
                `}
            >
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    <h2>CTCA Findings</h2>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(review?.ctcaFindings ?? '') }} />
                </div>
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    <h2>Non-Coronary Findings</h2>
                    <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(review?.nonCardiacFindings ?? '') }} />
                </div>
            </div>
            <Divider
                css={css`
                    margin: 1rem;
                `}
            />
            <div
                css={css`
                    width: 100%;
                    text-align: center;
                    margin-bottom: 2rem;
                `}
            >
                Following CT, all patients underwent urgent/semi-urgent catheter angiography. <br />
                The results are below.
            </div>
            <div
                css={css`
                    margin-bottom: 2rem;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                `}
            >
                <Button onClick={onBackToStudy}>
                    <ArrowBack
                        css={css`
                            margin-right: 0.75rem;
                        `}
                    />{' '}
                    Return to Study/Full CTCA
                </Button>
                {topRightContent}
            </div>
            <div
                css={css`
                    /* max-height: 85vh; */
                `}
            >
                <Tabs
                    defaultValue="cath"
                    color="red"
                    css={css`
                        max-height: 100%;
                    `}
                    keepMounted={false}
                >
                    <Tabs.List
                        css={css`
                            & > * {
                                font-size: 1.1rem;
                            }

                            [aria-selected='true'] {
                                /* background-color: aliceblue; */
                                font-weight: bold;
                                background-color: #ff002b26;
                            }
                        `}
                    >
                        <Tabs.Tab value="cath">Key Images</Tabs.Tab>
                        <Tabs.Tab value="angio">Completed Cardiac Catheter Angiography</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="cath">
                        <Tabs
                            orientation="vertical"
                            defaultValue={`aoe-${sortedAreasOfInterest?.[0]?.id}`}
                            color="red"
                            keepMounted={false}
                        >
                            <Tabs.List
                                css={css`
                                    /* padding: 2rem 1rem; */
                                    width: 12rem;
                                    background-color: #f8f8f8;
                                    /* gap: 0.5rem; */
                                    padding-top: 2rem;

                                    & > button {
                                        border-bottom: 1px solid #cfcfcf;
                                        border-radius: 0;

                                        justify-content: center;
                                        padding: inherit 1rem;
                                    }

                                    [aria-selected='true'] {
                                        background-color: #ff002b26;
                                        font-weight: bold;
                                    }
                                `}
                            >
                                {sortedAreasOfInterest?.length && (
                                    <div
                                        css={css`
                                            text-align: center;
                                            margin-bottom: 1rem;
                                        `}
                                    >
                                        Select an area of interest from the list below
                                    </div>
                                )}
                                {sortedAreasOfInterest?.map((a, idx) => (
                                    <Tabs.Tab
                                        css={css`
                                            ${idx === 0 && 'border-top: 1px solid #cfcfcf;'}
                                        `}
                                        value={`aoe-${a.id}`}
                                    >
                                        {a.title}
                                    </Tabs.Tab>
                                ))}
                            </Tabs.List>
                            {sortedAreasOfInterest?.map((a) => (
                                <Tabs.Panel value={`aoe-${a.id}`}>
                                    <InnerTabPanel
                                        ctImagePath={`${import.meta.env.VITE_APP_REVIEW_ASSET_BASE_URL}/${a.imageAssetPath}`}
                                        ohifUrl={StudyUtils.getOhifUrl({
                                            studyInstanceUid: a.dicomUid,
                                            token,
                                            closeLeftPanel: true
                                        })}
                                    />
                                </Tabs.Panel>
                            ))}
                        </Tabs>
                    </Tabs.Panel>
                    <Tabs.Panel value="angio">
                        {!!review?.angioUid && (
                            <div
                                css={css`
                                    min-height: 70vh;
                                    height: 100%;
                                    width: 100%;
                                    position: relative;
                                    flex: 1;
                                `}
                            >
                                <iframe
                                    src={StudyUtils.getOhifUrl({
                                        studyInstanceUid: review.angioUid,
                                        token,
                                        showSingleAngioInstancesLast: true
                                    })}
                                    css={css`
                                        height: 100%;
                                        width: 100%;
                                        position: absolute;
                                        border: none;
                                    `}
                                />
                            </div>
                        )}
                    </Tabs.Panel>
                </Tabs>
            </div>
        </div>
    );
}
