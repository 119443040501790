import 'shepherd.js/dist/css/shepherd.css';
import Shepherd from 'shepherd.js';
import { offset } from '@floating-ui/dom';

export const tour = new Shepherd.Tour({
    useModalOverlay: false,
    defaultStepOptions: {
        scrollTo: true,
        scrollToHandler: (el: HTMLElement) => {
            const yOffset = -20;
            const y = el.getBoundingClientRect().top + window.scrollY + yOffset;
            window.scrollTo({ behavior: 'smooth', top: y });
        },
        floatingUIOptions: {
            middleware: [offset({ mainAxis: 18 })]
        },
        cancelIcon: {
            enabled: true
        }
    }
});

tour.addStep({
    id: 'zoom',
    text: 'Click the "Show Case Questions" button to view the Case Questions',
    attachTo: {
        element: '[data-tour="show-questions"]',
        on: 'bottom'
    },
    buttons: [
        {
            text: 'Next',
            action: tour.next
        }
    ]
});

tour.addStep({
    id: 'questions',
    text: 'When you are ready, answer all of the questions, then click "Submit Answers"',
    attachTo: {
        element: '[data-tour="questions"]',
        on: 'left'
    },
    buttons: [
        {
            text: 'Next',
            action: tour.next
        }
    ]
});

tour.addStep({
    id: 'submit',
    text: 'Submit your answers once you have answered all of the questions',
    attachTo: {
        element: '[data-tour="submit-answers"]',
        on: 'left'
    },
    buttons: [
        {
            text: 'Done',
            action: tour.complete
        }
    ]
});
