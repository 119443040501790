import { create } from 'zustand';

interface StudyTimerStore {
    startTime: Date | null;
    setStartTime: () => void;
    endTime: Date | null;
    setEndTime: () => void;
}

export const useStudyTimerStore = create<StudyTimerStore>((set) => ({
    startTime: null,
    setStartTime: () => set({ startTime: new Date(), endTime: null }),
    endTime: null,
    setEndTime: () => set({ endTime: new Date() })
}));

useStudyTimerStore.subscribe((state) =>
    console.log(`[StudyTimerStore]`, { startTime: state.startTime, endTime: state.endTime })
);
