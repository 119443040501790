import { useState, useRef, useEffect } from 'react';
import CompletionBadge from './components/CompletionBadge';
import { useHistory } from 'react-router-dom';
import routes from 'config/routes';
import { useQuery } from '@apollo/client';
import { useGlobalStore } from 'store/zustand/store';
import { STUDY_REVIEW_BY_STUDY_ID } from 'graphql/queries';
import { StudyUtils } from 'utils/study-utils';
import { createPortal } from 'react-dom';
import { supabase } from 'index';
import { css } from '@emotion/react';
import { LoadingOverlay } from '@mantine/core';
import { StudyFeedbackModal } from './feedback-modal';
import { DumbCaseReviewPage } from './dumb-case-review-page';
import { SubmitButton } from '../CasePage/components/QuestionsDrawer/submit-button';
import ArrowForward from '@mui/icons-material/ArrowForward';

export default function CaseReviewPage() {
    const [showCompletionBadge, setShowCompletionBadge] = useState<boolean>(false);
    const logbookRef = useGlobalStore((state) => state.logbookRef);
    const nextButtonRef = useRef<HTMLButtonElement>(null);
    const history = useHistory();
    const pathCaseId = location.pathname.split(`${routes.AUTHED.CASE_REVIEW_PAGE.url}/`)?.[1];
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);

    const { data, loading, error } = useQuery(STUDY_REVIEW_BY_STUDY_ID, {
        variables: { id: Number(pathCaseId) },
        fetchPolicy: 'network-only'
    });

    const goToNextCase = () => {
        setTimeout(() => {
            history.push(routes.AUTHED.STUDY_PAGE.url);
        }, 500);
    };

    const [token, setToken] = useState<string>();
    useEffect(() => {
        supabase.auth.getSession().then((sess) => {
            const tk = sess.data.session?.access_token;
            console.log('got tk', tk);

            setToken(tk);
        });
    }, []);

    const readyToRenderCompletionBadge = !!logbookRef && !!nextButtonRef && !!nextButtonRef.current;
    const review = data?.study?.review;

    const onBackToStudy = () => {
        history.push(`${routes.AUTHED.STUDY_PAGE.url}/${data?.study?.id}`, { from: 'review' });
    };

    if (loading || !review || !token || !data?.study?.id) {
        return <LoadingOverlay visible={true} />;
    }

    const ohifUrl = StudyUtils.getOhifUrl({ studyInstanceUid: review.angioUid, token });
    const rootEl = document.getElementById('root')!;
    console.log('ohifUrl', ohifUrl);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
            `}
        >
            <StudyFeedbackModal
                opened={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
                studyId={data.study.id}
            />
            {showCompletionBadge &&
                readyToRenderCompletionBadge &&
                createPortal(
                    <CompletionBadge
                        initialPos={{
                            x: nextButtonRef!.current!.getBoundingClientRect().left,
                            y:
                                nextButtonRef!.current!.getBoundingClientRect().top -
                                rootEl.getBoundingClientRect().height
                        }}
                        targetPos={{
                            x: logbookRef.getBoundingClientRect().left,
                            y: logbookRef.getBoundingClientRect().top - rootEl.getBoundingClientRect().height
                        }}
                        onRestCallback={() => {
                            setShowCompletionBadge(false);
                            goToNextCase();
                        }}
                    />,
                    rootEl
                )}
            <DumbCaseReviewPage
                token={token}
                review={review}
                onBackToStudy={onBackToStudy}
                topRightContent={
                    <div
                        css={css`
                            display: flex;
                            gap: 1rem;
                        `}
                    >
                        <SubmitButton variant="light" onClick={() => setShowFeedbackModal(true)}>
                            Give feedback
                        </SubmitButton>
                        <SubmitButton ref={nextButtonRef} onClick={() => setShowCompletionBadge(true)}>
                            Go To Next Study{' '}
                            <ArrowForward
                                css={css`
                                    margin-left: 0.75rem;
                                `}
                            />
                        </SubmitButton>
                    </div>
                }
            />
        </div>
    );
}
