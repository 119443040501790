import { gql } from '__generated__';

export const SUBMIT_STUDY = gql(`
    mutation SubmitStudy($input: SubmitStudyInput!) {
        submitStudy(input: $input)
    }
`);

export const SET_CME_REGISTRATION = gql(`
    mutation SetCmeRegistration($input: CmeInput!) {
        registerCme(input: $input) {
            register
            college
            collegeId
            careerStage
            specialty
        }
    }
`);

export const UPDATE_SELF = gql(`
    mutation UpdateSelf($input: UpdateUserInput!) {
        updateSelf(input: $input) {
            id
        }
    }
`);

export const ADD_STUDY_FEEDBACK = gql(`
    mutation AddStudyFeedback($input: AddFeedbackInput!) {
        addFeedback(input: $input) {
            id
        }
    }
`);

export const MAKE_CHECKOUT_SESSION = gql(`
    mutation MakeCheckoutSession($input: CheckoutSessionInput!) {
        makeCheckoutSession(input: $input) {
            redirectUrl
        }
    }
`);

export const ADD_SUPPORT_CONTACT = gql(`
    mutation AddSupportContact($input: SupportContactInput!) {
        addSupportContact(input: $input) {
            id
        }
    }
`);
