import { css } from '@emotion/react';
import { Card, Group, Radio, Select, SimpleGrid, Text, TextInput } from '@mantine/core';
import { CmeSelect } from '../UserProfilePage/components/CME/select';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Schema } from './schema';
import { countries } from '../UserProfilePage/components/NoSubscriptionsModal/countries';

const referralSources = [
    { value: 'search-engine', label: 'Search Engine (Google, Yahoo, etc' },
    { value: 'word-of-mouth', label: 'Recommended by a friend or colleague' },
    { value: 'social-media', label: 'Social media' },
    { value: 'publication', label: 'Blog or publication' },
    { value: 'other', label: 'Other' }
];

const organisations = [
    { value: 'alfred', label: 'Alfred Hospital - Melbourne, Australia' },
    { value: 'monash', label: 'Monash University' },
    { value: 'unimelb', label: 'University of Melbourne' },
    { value: 'barwon-health', label: 'Barwon Health' },
    { value: 'none', label: 'None of the above' }
];

const pinnedCountries = countries.filter((c) => ['AU', 'US', 'GB'].includes(c.value));
const restCountries = countries.filter((c) => !['AU', 'US', 'GB'].includes(c.value));

export function OnboardingStepOne() {
    const { register, formState, watch, setValue } = useFormContext<Schema>();

    return (
        <div
            css={css`
                height: 100%;
                width: 100%;
                background-color: white;

                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <h2>About You</h2>
            <div
                css={css`
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;
                `}
            >
                <Card withBorder>
                    <SimpleGrid cols={2}>
                        <TextInput
                            {...register('aboutYou.firstName')}
                            label="First Name"
                            placeholder="John"
                            withAsterisk
                            error={formState.errors.aboutYou?.firstName?.message}
                        />
                        <TextInput
                            {...register('aboutYou.lastName')}
                            label="Last Name"
                            placeholder="Smith"
                            withAsterisk
                            error={formState.errors.aboutYou?.lastName?.message}
                        />
                        <Select
                            label="Country"
                            placeholder="Australia"
                            data={[
                                {
                                    group: '',
                                    items: pinnedCountries
                                },
                                {
                                    group: ' ',
                                    items: restCountries
                                }
                            ]}
                            withAsterisk
                            value={watch('aboutYou.country')}
                            onChange={(val) => setValue('aboutYou.country', val ?? '', { shouldValidate: true })}
                            error={formState.errors.aboutYou?.country?.message}
                        />
                    </SimpleGrid>
                </Card>
            </div>
        </div>
    );
}
