import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Button, ButtonProps, CircularProgress, Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 500,
        fontSize: '1.1em',
        border: 'none',
        borderRadius: 5,
        margin: theme.spacing(1, 'auto'),
        padding: '0.5em 1em',
        width: '12rem',
        textTransform: 'none',

        '&:hover': {
            backgroundColor:  '#747474'
        }
    },
    loading: {
        backgroundColor: '#942d42',
        color: theme.palette.text.disabled,

        '&:hover': {
            backgroundColor: '#202020'
        }
    }
}));

interface Props extends ButtonProps {
    children: React.ReactNode;
    loading?: boolean;
}

const CtaButton: React.FunctionComponent<Props> = (props) => {
    const { children, loading, ...rest } = props;
    const { classes, cx } = useStyles();

    return (
        <Button
            classes={{
                root: classes.root
            }}
            className={cx(loading && classes.loading)}
            {...rest}
        >
            {loading ? <CircularProgress style={{ color: '#222222', height: '1.8em', width: '1.8rem' }} /> : children}
        </Button>
    );
};

export default CtaButton;
