import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    body {
        /* align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center; */
        height: 100vh;
        font-family: Roboto, Helvetica, Arial, sans-serif;
        /* transition: all 0.5s linear; */
    }
`;

export interface Theme {
    palette: {
        primary: string;
        secondary: string;
        backgroundColor: string;
    };
    typography: {
        primary: string;
        secondary: string;
        fontFamily: string;
    };
    sideNav: {
        width: string;
        collapsedWidth: string;
    };
}

export const lightTheme: Theme = {
    palette: {
        primary: '#083c70',
        secondary: '#2da811',
        backgroundColor: '#ffffff'
    },
    typography: {
        primary: '#424242',
        secondary: '#616161',
        fontFamily: 'Roboto'
    },
    sideNav: {
        width: '16rem',
        collapsedWidth: '6rem'
    }
};
