import { useState } from 'react';
import { css } from '@emotion/react';
import { Button, Stepper } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { UseEmblaCarouselType } from 'embla-carousel-react';
import { mq } from 'config/breakpoints';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import routes from 'config/routes';

interface StepProps {
    children: React.ReactNode;
    imagePath: string;
    videoPath: string;
}

const Step = (props: StepProps) => {
    const { children, imagePath, videoPath } = props;
    const [showImage, setShowImage] = useState(false);

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                max-height: 80vh;
                gap: 1rem;
            `}
        >
            <div
                css={css`
                    max-width: 100%;
                    max-height: 70%;
                `}
            >
                {showImage ? (
                    <img
                        src={imagePath}
                        css={css`
                            width: auto;
                            height: 100%;
                            border: 1px solid #dfdfdf;
                        `}
                    />
                ) : (
                    <video
                        autoPlay
                        loop
                        muted
                        css={css`
                            width: auto;
                            height: 100%;
                            border: 1px solid #dfdfdf;
                        `}
                        onError={() => setShowImage(true)}
                    >
                        <source src={videoPath} type="video/mp4" />
                    </video>
                )}
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                `}
            >
                {children}
            </div>
        </div>
    );
};

const NUM_STEPS = 7;

export function BeginTrainingPage() {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [embla, setEmbla] = useState<UseEmblaCarouselType[1] | null>(null);
    const history = useHistory();

    const next = () => {
        setActiveStep((a) => (a + 1 >= NUM_STEPS ? NUM_STEPS : a + 1));
        embla?.scrollNext();
    };

    const back = () => {
        setActiveStep((a) => (a - 1 < 0 ? 0 : a - 1));
        embla?.scrollPrev();
    };

    return (
        <div
            css={css`
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9999999;
                height: 100vh;
                max-height: 100vh;
                overflow-x: hidden;
                width: 100vw;
                background-color: white;

                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0 10%;
            `}
        >
            <h2>The Cardiac CT Course Quickstart Guide</h2>
            <Stepper
                active={activeStep}
                size="xs"
                color="#ad132d"
                css={css`
                    width: 90%;

                    ${mq('lg')} {
                        width: 70%;
                    }

                    margin-top: 1rem;
                    /* margin-bottom: 2rem; */
                `}
            >
                {new Array(NUM_STEPS).fill(0).map(() => (
                    <Stepper.Step />
                ))}
            </Stepper>
            <div
                css={css`
                    margin-top: 2rem;
                    margin-bottom: 1rem;
                    width: 100%;
                `}
            >
                {activeStep >= NUM_STEPS ? (
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin: 2rem 0;
                            gap: 3rem;
                        `}
                    >
                        <motion.div
                            animate={{
                                boxShadow: [
                                    '0px 0px 9px 7px rgba(23, 193, 0, 0.2)',
                                    '0px 0px 18px 14px rgba(23, 193, 0, 0.4)',
                                    '0px 0px 9px 7px rgba(23, 193, 0, 0.2)'
                                ]
                            }}
                            transition={{
                                ease: 'easeInOut',
                                repeat: Infinity,
                                duration: 1.5
                            }}
                        >
                            <Button color="green" size="lg" onClick={() => history.push(routes.AUTHED.STUDY_PAGE.url)}>
                                BEGIN TRAINING
                            </Button>
                        </motion.div>
                        <Button onClick={back} color="gray" variant="subtle">
                            Back
                        </Button>
                    </div>
                ) : (
                    <div
                        css={css`
                            width: 100%;
                            display: flex;
                            justify-content: space-around;
                        `}
                    >
                        <Button onClick={back} color="#ad132d">
                            Back
                        </Button>

                        <div
                            css={css`
                                display: flex;
                                gap: 1rem;
                            `}
                        >
                            {/* <Button variant="subtle" color="gray">
                            Skip
                        </Button> */}
                            <Button onClick={next} color="#ad132d">
                                Next
                            </Button>
                        </div>
                    </div>
                )}
            </div>

            {activeStep < NUM_STEPS && (
                <div
                    css={css`
                        position: relative;
                        height: 80%;
                    `}
                >
                    <Carousel getEmblaApi={setEmbla} withControls={false} height="100%">
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-1.mov" imagePath="/images/quickstart-1.png">
                                <p>
                                    Show and hide the Case Questions by clicking the "Show/Hide Case Questions" button.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-2.mov" imagePath="/images/quickstart-2.png">
                                <p>Use the toolbar at the top of the DICOM viewer to select a tool.</p>
                                <p
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    The default tool is stack scroll, click and drag vertically to scroll through
                                    images.
                                    <br />
                                    Tools available include Zoom, Contrast, Pan, Stack Scroll, and many more.
                                    <br />
                                    Once a tool is selected, click and drag on the DICOM image to use that tool.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-3.mov" imagePath="/images/quickstart-3.png">
                                <p>
                                    Select a different image by double clicking an option in the left sidebar, or click
                                    and drag the desired series on to the main DICOM viewer.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-7.mov" imagePath="/images/quickstart-7.png">
                                <p
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    You can open multiple series at once by clicking the Grid button.
                                    <br />
                                    With multiple series open, you can use the Reference Lines tool and the Synchronised
                                    Stack Scroll tool.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-4.mov" imagePath="/images/quickstart-4.png">
                                <p>
                                    Once ready, complete the Case Questions and submit your answers by clicking the
                                    "Submit Answers" button.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-5.mov" imagePath="/images/quickstart-5.png">
                                <p
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    Review your answers.
                                    <br />
                                    Each question is graded as Correct or Incorrect.
                                    <br />
                                    Note which areas on the diagram were selected correctly, in-error, or missed.
                                </p>
                            </Step>
                        </Carousel.Slide>
                        <Carousel.Slide>
                            <Step videoPath="/videos/quickstart-6.mov" imagePath="/images/quickstart-6.png">
                                <p>
                                    Review the annotated key CT images, along with their cardiac angiography
                                    counterpart.
                                </p>
                            </Step>
                        </Carousel.Slide>
                    </Carousel>
                </div>
            )}
        </div>
    );
}
