import { useEffect, useRef } from 'react';
import { Chart, Plugin } from 'chart.js';
import { css } from '@emotion/react';

interface Props {
    totalCases: number;
    completedCases: number;
}

export function CaseCompletions(props: Props) {
    const { totalCases, completedCases } = props;
    const ref = useRef<HTMLCanvasElement>(null);

    const completedPercent = (100 * completedCases) / totalCases;
    const remainingPercent = 100 - completedPercent;

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const innerLabel: Plugin = {
            id: 'innerLabel',
            afterDatasetDraw(chart, args, pluginOptions) {
                const { ctx } = chart;
                const meta = args.meta;
                const xCoor = meta.data[0].x;
                const yCoor = meta.data[0].y;
                ctx.save();
                ctx.textAlign = 'center';
                ctx.font = '32px sans-serif';
                ctx.fillText(completedPercent.toFixed(0) + '%', xCoor, yCoor);
                ctx.restore();
            }
        };

        const chart = new Chart(ref.current, {
            type: 'doughnut',
            plugins: [innerLabel],
            data: {
                labels: ['Studies Completed', 'Studies Remaining'],
                datasets: [
                    {
                        data: [completedPercent, remainingPercent],
                        backgroundColor: ['rgba(75,192,192,0.4)', '#f3f3f3'],
                        borderColor: ['rgba(75,192,192,1)', '#cccccc'],
                        spacing: remainingPercent > 0 ? 4 : 0,
                        borderWidth: 1
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(tooltipItem) {
                                return `${tooltipItem.raw}% (${(Number(tooltipItem.raw) * totalCases) / 100} studies)`;
                            }
                        }
                    }
                }
            }
        });

        return () => chart.destroy();
    }, [completedCases, completedPercent, remainingPercent, totalCases]);

    return (
        <div
            css={css`
                padding: 1.5rem;
            `}
        >
            <canvas ref={ref} />
        </div>
    );
}
