import { css } from '@emotion/react';
import { LoadingOverlay } from '@mantine/core';
import { StudyFieldsFragment, StudyWithAnswerFieldsFragment } from '__generated__/graphql';
import { useEffect, useRef, useState } from 'react';

interface Props {
    studyForRender: StudyFieldsFragment | StudyWithAnswerFieldsFragment;
    ohifUrl: string;
    token?: string;
}

export function DumbCasePage(props: Props) {
    const { ohifUrl, token } = props;
    const [iframeLoading, setIframeLoading] = useState(true);
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        if (!iframeRef.current || !token) {
            return;
        }

        iframeRef.current.onload = () => {
            console.log('POST sending message to ohif', { token });
            iframeRef.current?.contentWindow?.postMessage({ token }, import.meta.env.VITE_APP_DICOM_BASE_URL);
        };
    }, [token]);

    return (
        <>
            <LoadingOverlay visible={iframeLoading} bg="#0000004b" zIndex="9999" />
            <div
                css={css`
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;

                    & iframe {
                        height: calc(100% - 5px);
                        width: 100%;
                        box-sizing: border-box;
                        border: none;
                    }

                    & #tim-dicom-root {
                        height: calc(100% - 5px);
                        width: 100%;
                        box-sizing: border-box;
                        border: none;
                    }
                `}
            >
                <iframe
                    src={ohifUrl}
                    ref={iframeRef}
                    allow="xr-spatial-tracking"
                    onLoad={() => setIframeLoading(false)}
                />
            </div>
        </>
    );
}
