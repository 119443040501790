import routes from 'config/routes';
import { Route, Switch, useHistory, useLocation } from 'react-router';
import { OnboardingStepOne } from './step-one';
import { Stepper } from '@mantine/core';
import { css } from '@emotion/react';
import { OnboardingStepTwo } from './step-two';
import { OnboardingStepThree } from './step-three';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import CtaButton from 'components/_new/Button';
import { Schema, schema } from './schema';

export function Onboarding() {
    const methods = useForm<Schema>({
        resolver: zodResolver(schema),
        reValidateMode: 'onChange'
    });
    const { trigger, formState } = methods;
    const history = useHistory();
    const location = useLocation();
    const steps: string[] = [
        routes.AUTHED.ONBOARDING.STEP_ONE.url,
        routes.AUTHED.ONBOARDING.STEP_TWO.url,
        routes.AUTHED.ONBOARDING.STEP_THREE.url
    ];
    const currStepIndex = steps.indexOf(location.pathname);

    const onBack = () => {
        if (currStepIndex > 0) {
            history.push(steps[currStepIndex - 1]);
        }
    };

    const onNext = async () => {
        await trigger();
        console.log(formState.errors);

        if (currStepIndex === 0) {
            if (!formState.errors.aboutYou) {
                history.push(steps[currStepIndex + 1]);
            }
        } else if (currStepIndex === 1) {
            if (!formState.errors.professionalHistory) {
                history.push(steps[currStepIndex + 1]);
            }
        } else if (currStepIndex === 2) {
            if (!formState.errors.cme) {
                // submit form and go to payment
            }
        }
    };

    return (
        <div>
            <div
                css={css`
                    width: min(90%, 60rem);
                    margin: 0 auto;
                    padding: 2rem;
                    position: relative;
                `}
            >
                <Stepper active={currStepIndex}>
                    <Stepper.Step label="Step 1" description="About You" />
                    <Stepper.Step label="Step 2" description="Professional History" />
                    <Stepper.Step label="Step 3" description="CME Registration" />
                </Stepper>
            </div>
            <div
                css={css`
                    margin: 0 auto;
                    margin-top: 2rem;
                    width: min(90%, 45rem);
                `}
            >
                <FormProvider {...methods}>
                    <Switch>
                        <Route path={routes.AUTHED.ONBOARDING.STEP_ONE.url} component={OnboardingStepOne} />
                        <Route path={routes.AUTHED.ONBOARDING.STEP_TWO.url} component={OnboardingStepTwo} />
                        <Route path={routes.AUTHED.ONBOARDING.STEP_THREE.url} component={OnboardingStepThree} />
                    </Switch>
                </FormProvider>
                <div
                    css={css`
                        width: 100%;
                        min-height: 4rem;
                        display: flex;
                        justify-content: space-around;
                        margin-top: 2rem;
                    `}
                >
                    <CtaButton
                        css={css`
                            margin-left: 0;
                            margin-right: 0;
                            border-radius: 100px;
                            height: 2.75rem;
                            width: 8rem;
                        `}
                        onClick={onBack}
                    >
                        Back
                    </CtaButton>
                    <CtaButton
                        css={css`
                            margin-left: 0;
                            margin-right: 0;
                            border-radius: 100px;
                            height: 2.75rem;
                            width: 8rem;
                        `}
                        onClick={onNext}
                    >
                        Next
                    </CtaButton>
                </div>
            </div>
        </div>
    );
}
