import SideNav from './components/SideNav';
import styled from 'styled-components';
import { Switch, Route, useLocation } from 'react-router-dom';
import routes from 'config/routes';
import UserProgressPage from 'pages/Authed/UserProgressPage';
import CasePage from 'pages/Authed/CasePage';
import CaseReviewPage from 'pages/Authed/CaseReviewPage/CaseReviewPage';
import LogbookPage from 'pages/Authed/LogbookPage';
import UserProfilePage from 'pages/Authed/UserProfilePage';
import AuthedSupportPage from 'pages/Authed/SupportPage';
import { NoSubscriptionsModal } from 'pages/Authed/UserProfilePage/components/NoSubscriptionsModal';
import { useCheckSubscription } from './use-check-subscription';
import { LoadingOverlay } from '@mantine/core';
import { Onboarding } from 'pages/Authed/Onboarding';
import { BeginTrainingPage } from 'pages/Authed/BeginTrainingPage';

const PagesWithoutSideNav: string[] = [
    routes.AUTHED.ONBOARDING.url,
    routes.AUTHED.ONBOARDING.STEP_ONE.url,
    routes.AUTHED.ONBOARDING.STEP_TWO.url,
    routes.AUTHED.ONBOARDING.STEP_THREE.url
];

export default function Authed() {
    const location = useLocation();
    const { showModal, loading } = useCheckSubscription();

    return (
        <StyledContainer>
            <NoSubscriptionsModal opened={showModal} />
            <LoadingOverlay visible={loading} />
            {!PagesWithoutSideNav.includes(location.pathname) && <SideNav />}
            <StyledPageContentContainer
                noSideNav={PagesWithoutSideNav.includes(location.pathname)}
                casePage={location.pathname.includes(routes.AUTHED.STUDY_PAGE.url)}
            >
                <Switch>
                    <Route exact path={routes.AUTHED.USER_PROFILE_PAGE.url} component={UserProfilePage} />
                    <Route exact path={routes.AUTHED.USER_PROGRESS_PAGE.url} component={UserProgressPage} />
                    <Route path={routes.AUTHED.CASE_REVIEW_PAGE.url} component={CaseReviewPage} />
                    <Route path={routes.AUTHED.STUDY_PAGE.url}>
                        <CasePage />
                    </Route>
                    <Route exact path={routes.AUTHED.LOGBOOK_PAGE.url} component={LogbookPage} />
                    <Route exact path={routes.AUTHED.SUPPORT_PAGE.url} component={AuthedSupportPage} />
                    <Route path={routes.AUTHED.ONBOARDING.url} component={Onboarding} />
                    <Route exact path={routes.AUTHED.BEGIN_TRAINING.url} component={BeginTrainingPage} />
                </Switch>
            </StyledPageContentContainer>
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    height: 100%;
    && {
        padding: 0;
    }
    display: flex;
`;

type ContainerProps = {
    casePage: boolean;
    noSideNav: boolean;
};

const StyledPageContentContainer = styled.div`
    padding: ${(props: ContainerProps) => {
        if (props.noSideNav || props.casePage) {
            return 0;
        }

        return '1rem';
    }};
    max-width: ${(props: ContainerProps) => {
        if (props.casePage) {
            return '100%';
        }

        if (props.casePage) {
            // @ts-expect-error styled-components
            return `calc(100% - ${props.theme.sideNav.collapsedWidth});`;
        }

        // @ts-expect-error styled-components
        return `calc(100% - ${props.theme.sideNav.width};)`;
    }};
    margin-left: ${(props: ContainerProps) => {
        if (props.noSideNav) {
            return 0;
        }

        if (props.casePage) {
            // @ts-expect-error styled-components types
            return props.theme.sideNav.collapsedWidth;
        }

        // @ts-expect-error styled-components types
        return props.theme.sideNav.width;
    }};
    width: 100%;
`;
