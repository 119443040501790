import { Switch, Route, useLocation, useHistory, Redirect } from 'react-router-dom';
import routes from 'config/routes';
import Headerbar from './components/Headerbar';
import Footer from './components/Footer';
import HomePage from 'pages/Public/HomePage';
import AboutPage from 'pages/Public/AboutPage';
import AccreditationPage from 'pages/Public/AccreditationPage';
import LogbookPage from 'pages/Public/LogbookPage';
import PricingPage from 'pages/Public/PricingPage';
import SignUpPage from 'pages/Public/SignUpPage';
import styled from 'styled-components';
import PaymentResultPage from 'pages/Public/PaymentResultPage';
import SignupSuccessPage from 'pages/Public/SignupSuccessPage';
import SupportPage from 'pages/Public/SupportPage';
import { css } from '@emotion/react';
import { MobileMenu } from './components/MobileMenu';
import { Burger, Button } from '@mantine/core';
import { useGlobalStore } from 'store/zustand/store';
import { useMediaQuery } from '@mantine/hooks';
import { breakpoints } from 'config/breakpoints';
import { PrivacyPolicyPage } from 'pages/Public/PrivacyPolicyPage';
import { DemoPage } from 'pages/Public/DemoPage';

const ContentContainer = styled.div<{ fullWidth: boolean }>`
    /* margin: 2rem 0; */
    min-height: 100%;
`;

const fullWidthPages: string[] = [routes.PUBLIC.SIGNUP_PAGE.url];

type AuthedRoutes = keyof typeof routes.AUTHED;

function Public() {
    const history = useHistory();
    const location = useLocation();
    const [publicNavOpen, setPublicNavOpen] = useGlobalStore((state) => [state.publicNavOpen, state.setPublicNavOpen]);
    const isMobile = useMediaQuery(`(max-width: ${breakpoints.lg}px)`);

    const authedUrls: Array<AuthedRoutes | string> = (Object.keys(routes.AUTHED) as AuthedRoutes[]).map(
        (r) => routes.AUTHED[r].url
    );
    if (authedUrls.includes(location.pathname)) {
        console.log('PublicLayout routing to HOME_PAGE');
        // history.push(routes.PUBLIC.HOME_PAGE.url);
    }

    return (
        <div>
            {/* {isMobile ? (
                <>
                    <div
                        css={css`
                            position: absolute;
                            top: 0;
                            padding: 1rem;
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        `}
                    >
                        <Burger opened={publicNavOpen} onClick={() => setPublicNavOpen(!publicNavOpen)} />
                        <Button
                            onClick={() => history.push(`${routes.PUBLIC.SIGNUP_PAGE.url}?mode=join`)}
                            css={css`
                                width: fit-content;
                                height: fit-content;
                                font-weight: bold;
                                padding: 0.5rem 1rem;
                                border-radius: 1000px;
                                background-color: black;
                                color: white;
                            `}
                        >
                            Register Now
                        </Button>
                    </div>
                    <MobileMenu />
                </>
            ) : (
                <Headerbar />
            )} */}
            <ContentContainer fullWidth={fullWidthPages.includes(location.pathname)}>
                <Switch>
                    {/* <Route exact path={routes.PUBLIC.SIGNUP_SUCCESS.url} component={SignupSuccessPage} />
                    <Route exact path={routes.PUBLIC.HOME_PAGE.url} component={HomePage} />
                    <Route exact path={routes.PUBLIC.ABOUT_PAGE.url} component={AboutPage} />
                    <Route exact path={routes.PUBLIC.ACCREDITATION_PAGE.url} component={AccreditationPage} />
                    <Route exact path={routes.PUBLIC.LOGBOOK_PAGE.url} component={LogbookPage} />
                    <Route exact path={routes.PUBLIC.PRICING_PAGE.url} component={PricingPage} />
                    <Route exact path={routes.PUBLIC.PAYMENT.url} component={PaymentResultPage} />
                    <Route exact path={routes.PUBLIC.FAQ.url} component={SupportPage} />
                    <Route exact path={routes.PUBLIC.PRIVACY_POLICY.url} component={PrivacyPolicyPage} /> */}
                    <Route exact path={routes.PUBLIC.SIGNUP_PAGE.url} component={SignUpPage} />
                    <Route exact path={routes.PUBLIC.DEMO.url} component={DemoPage} />
                    {/* <Redirect to={routes.PUBLIC.SIGNUP_PAGE.url} /> */}

                    {/* <Route exact path="/case" component={CasePageContainer} /> */}
                    {/*
                     */}
                    {/* <Route exact path="/progress" component={ProgressPageContainer} /> */}
                    {/* <Route exact path="/checkout" component={CheckoutPageContainer} /> */}
                    {/* 
                <Route component={NotFoundPage} /> */}
                </Switch>
            </ContentContainer>
            {/* <Footer /> */}
        </div>
    );
}

export default Public;
