import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(173, 13, 45)',
            light: '#ed2f55',
            dark: '#002c52'
        },
        secondary: {
            main: '#7b0a20',
            light: '#61e18d',
            dark: '#007d33'
        },
        error: {
            main: '#ff4322',
            light: '#ff7a4f',
            dark: '#c30000'
        },
        warning: {
            main: '#ffd322',
            light: '#fff9c4',
            dark: '#624e28'
        },
        info: {
            main: '#1dcbfb',
            light: '#71feff',
            dark: '#009ac8'
        },
        success: {
            main: '#22ae5f',
            light: '#61e18d',
            dark: '#007d33'
        },
        text: {
            primary: '#313943',
            secondary: '#5b636e',
            disabled: '#838f8f',
            // @ts-ignore
            hint: '#d5dad7'
        }
    },
    typography: {
        fontFamily: ['sans-serif'].join(','),
        body2: {
            fontWeight: 500
        }
    },
    cardiac: {
        stub: 'stub',
    }
});

export default theme;
