import { useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import { Button, LoadingOverlay, Textarea, Text } from '@mantine/core';
import { mq } from 'config/breakpoints';
import routes from 'config/routes';
import { ADD_SUPPORT_CONTACT } from 'graphql/mutations';
import { useState } from 'react';
import { useHistory } from 'react-router';

export default function AuthedSupportPage() {
    const history = useHistory();
    const [addSupportContact] = useMutation(ADD_SUPPORT_CONTACT);
    const [content, setContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(ev.target.value);
    };

    const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setLoading(true);
        setSubmitted(false);

        if (content.length) {
            try {
                await addSupportContact({
                    variables: {
                        input: {
                            content
                        }
                    }
                });
            } catch (error) {
                console.log('error submitting support content');
                console.log(error);
            }
        }

        setLoading(false);
        setSubmitted(true);
    };

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;

                & > * {
                    padding: 2rem 0;
                    border-bottom: 1px solid #c4c4c4;
                }
            `}
        >
            <LoadingOverlay visible={loading} />
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                `}
            >
                For a quick tutorial on how to use The Cardiac CT Course, rewatch the quickstart training guide.
                <Button onClick={() => history.push(routes.AUTHED.BEGIN_TRAINING.url)} w="fit-content" color="#ad132d">
                    View Quickstart Guide
                </Button>
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                `}
            >
                If you have any queries, would like to create a course with us, or would like to work with us, please
                let us know below.
                <form
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 1rem;

                        ${mq('sm')} {
                            width: 30%;
                        }
                    `}
                    onSubmit={onSubmit}
                >
                    <Textarea
                        w="100%"
                        minRows={7}
                        autosize
                        value={content}
                        onChange={onChange}
                        maxLength={1000}
                        disabled={loading || submitted}
                    />
                    <div
                        css={css`
                            margin-top: -16px;
                            font-size: 0.75rem;
                            color: #a8a8a8;
                        `}
                    >
                        {content.length} / 1000
                    </div>
                    <Button
                        css={css`
                            width: fit-content;
                        `}
                        type="submit"
                        color="#ad132d"
                        disabled={loading || submitted}
                    >
                        Submit
                    </Button>
                    {submitted && <Text>Thankyou for your feedback.</Text>}
                </form>
            </div>
        </div>
    );
}
