export default {
    PUBLIC: {
        HOME_PAGE: {
            url: '/'
        },
        SIGNUP_PAGE: {
            url: '/sign-up',
            STEP_TWO: {
                url: '/sign-up/step-2'
            }
        },
        ABOUT_PAGE: {
            url: '/about'
        },
        ACCREDITATION_PAGE: {
            url: '/accreditation'
        },
        LOGBOOK_PAGE: {
            url: '/logbook'
        },
        PRICING_PAGE: {
            url: '/pricing'
        },
        FAQ: {
            url: '/faq'
        },
        PAYMENT: {
            url: '/payment'
        },
        SIGNUP_SUCCESS: {
            url: '/checkout/success'
        },
        PRIVACY_POLICY: {
            url: '/privacy-policy'
        },
        DEMO: {
            url: '/demo'
        }
    },
    AUTHED: {
        USER_PROFILE_PAGE: {
            url: '/app/profile'
        },
        USER_PROGRESS_PAGE: {
            url: '/app/progress'
        },
        STUDY_PAGE: {
            url: '/app/studies'
        },
        CASE_REVIEW_PAGE: {
            url: '/app/study-review'
        },
        LOGBOOK_PAGE: {
            url: '/app/logbook'
        },
        SUPPORT_PAGE: {
            url: '/app/support'
        },
        ONBOARDING: {
            url: '/app/onboarding',
            STEP_ONE: {
                url: '/app/onboarding/step-one'
            },
            STEP_TWO: {
                url: '/app/onboarding/step-two'
            },
            STEP_THREE: {
                url: '/app/onboarding/step-three'
            }
        },
        BEGIN_TRAINING: {
            url: '/app/begin-training'
        }
    }
} as const;
