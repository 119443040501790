import { Select, SelectProps, TextInput } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { Schema } from '.';
import { css } from '@emotion/react';

export interface Props extends SelectProps {
    fieldName: keyof Pick<Schema, 'college' | 'careerStage' | 'specialty'>;
}

export function CmeSelect(props: Props) {
    const { disabled, fieldName } = props;
    const { register, watch, setValue, formState, trigger } = useFormContext<Schema>();
    const presetName = `${fieldName}.preset` as const;
    const manualName = `${fieldName}.manual` as const;

    console.log('watch', watch());
    console.log('errors', formState.errors);

    const onChange = (val: string | null) => {
        setValue(presetName, val ?? '');
        trigger(presetName);
    };

    return (
        <div
            css={css`
                width: 100%;
            `}
        >
            <Select
                {...props}
                value={watch(presetName)}
                w="100%"
                onChange={onChange}
                error={formState.errors[fieldName]?.preset?.message}
            />
            {watch(presetName) === 'other' && (
                <TextInput
                    {...register(manualName)}
                    error={formState.errors[fieldName]?.manual?.message}
                    label="Specify Other"
                    disabled={disabled}
                    w="100%"
                    withAsterisk
                />
            )}
        </div>
    );
}
