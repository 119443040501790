export const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1800
} as const;

export const mq = (n: keyof typeof breakpoints) => {
    return `@media (min-width: ${breakpoints[n]}px)`;
};
