interface OhifUrlArgs {
    studyInstanceUid: string; // study UID
    token: string;
    closeLeftPanel?: boolean;
    seriesInstanceUid?: string; // series UID (must be a series within the specified study UID)
    showSingleAngioInstancesLast?: boolean;
}

// const getOhifUrl = (instanceUid: string, token: string, closePanel?: boolean, seriesInstanceUid?: string) =>
//     `${import.meta.env.VITE_APP_DICOM_BASE_URL}/viewer?StudyInstanceUIDs=${instanceUid}${seriesInstanceUid ? `&SeriesInstanceUID=${seriesInstanceUid}` : ''}&closeLeftPanel=${String(!!closePanel)}&token=${token}`;
// // &hangingprotocolId=mprAnd3DVolumeViewport

const getOhifUrl = (args: OhifUrlArgs) => {
    const { studyInstanceUid, token, closeLeftPanel, seriesInstanceUid, showSingleAngioInstancesLast } = args;

    const url = new URL(`${import.meta.env.VITE_APP_DICOM_BASE_URL}/viewer`);
    url.searchParams.append('StudyInstanceUIDs', studyInstanceUid);

    if (seriesInstanceUid) {
        url.searchParams.append('SeriesInstanceUID', seriesInstanceUid);
    }

    url.searchParams.append('closeLeftPanel', String(!!closeLeftPanel));
    url.searchParams.append('showSingleAngioInstancesLast', String(!!showSingleAngioInstancesLast));
    url.searchParams.append('token', token);

    return url.toString();
};

export const StudyUtils = { getOhifUrl };
