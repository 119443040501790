import { css } from '@emotion/react';

interface InnerTabPanelProps {
    ctImagePath: string;
    ohifUrl: string;
}

export function InnerTabPanel(props: InnerTabPanelProps) {
    const { ctImagePath, ohifUrl } = props;

    return (
        <div
            css={css`
                display: flex;
                justify-content: space-between;
                width: 100%;
                min-height: 60vh;
                max-height: 75vh;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    background-color: black;
                `}
            >
                <img
                    src={ctImagePath}
                    crossOrigin="anonymous"
                    css={css`
                        height: auto;
                        max-height: 100%;
                        width: 100%;
                    `}
                />
            </div>

            <div
                css={css`
                    position: relative;
                    flex: 1;

                    display: flex;
                `}
            >
                <iframe
                    src={ohifUrl}
                    css={css`
                        flex: 1 1 auto;
                        border: none;
                    `}
                />
            </div>
        </div>
    );
}
