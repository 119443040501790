import { z } from 'zod';

const requireManualIfOther = (val: { preset: string; manual?: string }) => {
    if (val.preset !== 'other') {
        return true;
    }

    return !!val.manual;
};

export const schema = z.object({
    aboutYou: z.object({
        firstName: z.string().min(1, 'Please enter your first name'),
        lastName: z.string().min(1, 'Please enter your last name'),
        country: z.string({ required_error: 'Please select a country' }).min(1, 'Please select a country')
    }),
    professionalHistory: z.object({
        jurisdiction: z
            .string({ required_error: 'Please select a jurisdiction' })
            .min(1, 'Please select a jurisdiction'),
        yearsReporting: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an opton'),
        numCts: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an opton'),
        specialty: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an opton')
    }),
    cme: z.object({
        register: z.boolean(),
        college: z
            .object({
                preset: z.string().min(1, 'Please select a college'),
                manual: z.string().optional()
            })
            .default({ preset: '', manual: '' })
            .refine(requireManualIfOther, {
                message: 'College is required',
                path: ['manual']
            }),
        collegeId: z.string().min(1, 'Please enter College ID'),
        careerStage: z
            .object({
                preset: z.string().min(1, 'Please select a career stage'),
                manual: z.string().optional()
            })
            .default({ preset: '', manual: '' })
            .refine(requireManualIfOther, { message: 'Career Stage is required', path: ['manual'] }),
        specialty: z
            .object({
                preset: z.string().min(1, 'Please select a specialty'),
                manual: z.string().optional()
            })
            .default({ preset: '', manual: '' })
            .refine(requireManualIfOther, { message: 'Specialty is required', path: ['manual'] })
    })
});

export type Schema = z.infer<typeof schema>;
