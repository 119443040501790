import { useQuery } from '@apollo/client';
import { useFragment } from '__generated__';
import { NEXT_STUDY, STUDY_BY_ID, STUDY_FRAGMENT } from 'graphql/queries';

type QueryArgs = { skip?: boolean };
type ById = { studyId: number; next?: null };
type Next = { studyId?: null; next: boolean };

type Props = (ById | Next) & { queryOptions?: QueryArgs };

export function useGetStudy(props: Props) {
    const { studyId, next } = props;

    const runStudyById = studyId && !next;
    const studyById = useQuery(STUDY_BY_ID, {
        ...props.queryOptions,
        variables: { id: studyId! },
        skip: !runStudyById || props.queryOptions?.skip,
        fetchPolicy: 'network-only'
    });

    const runNextStudy = !studyId && !!next;
    const nextStudy = useQuery(NEXT_STUDY, {
        skip: !runNextStudy,
        fetchPolicy: 'network-only'
    });

    // const runStudyByIdWithAnswers = studyId && !next && withAnswers;
    // const studyByIdWithAnswers = useQuery(STUDY_BY_ID_WITH_ANSWERS, {
    //     skip: !runStudyByIdWithAnswers
    // });

    const studyData = useFragment(STUDY_FRAGMENT, studyById?.data?.study ?? nextStudy?.data?.nextStudy);

    console.log('useGetStudy', { studyData, props });

    if (runStudyById) {
        return {
            ...studyById,
            studyData
        };
    }

    if (runNextStudy) {
        return {
            ...nextStudy,
            studyData
        };
    }

    // if (runStudyByIdWithAnswers) {
    //     return studyByIdWithAnswers;
    // }

    return null;
}
