import { ComboboxItem } from '@mantine/core';

export const referralSources = [
    { value: 'search-engine', label: 'Search Engine (Google, Yahoo, etc' },
    { value: 'word-of-mouth', label: 'Recommended by a friend or colleague' },
    { value: 'social-media', label: 'Social media' },
    { value: 'publication', label: 'Blog or publication' },
    { value: 'other', label: 'Other' }
];

export const organisations = [
    { value: 'alfred', label: 'Alfred Hospital - Melbourne, Australia' },
    { value: 'monash', label: 'Monash University' },
    { value: 'unimelb', label: 'University of Melbourne' },
    { value: 'barwon-health', label: 'Barwon Health' },
    { value: 'none', label: 'None of the above' }
];

export const jurisdictions = [
    { value: 'aunz', label: 'Australia / New Zealand' },
    { value: 'usa', label: 'United States of America' },
    { value: 'uk', label: 'United Kingdom' },
    { value: 'eu', label: 'Europe' },
    { value: 'other', label: 'Other' }
];

export const yearsReporting = [
    { value: 'lt1', label: 'Less than 1 year' },
    { value: '1-3', label: '1-3 years' },
    { value: '4-5', label: '4-5 years' },
    { value: 'mt5', label: 'More than 5 years' }
];

export const ctsReported = [
    { value: 'lt10', label: 'Less than 10' },
    { value: '1049', label: '10 - 49' },
    { value: '5099', label: '50 - 99' },
    { value: 'mt100', label: '100 or more' }
];

export const specialtyOptions: ComboboxItem[] = [
    { label: 'Cardiology', value: 'cardiology' },
    { label: 'Radiology', value: 'radiology' },
    { label: 'Nuclear Medicine', value: 'nucmed' },
    { label: 'Other', value: 'other' }
];

export const collegeOptions: ComboboxItem[] = [
    { label: 'CSANZ - Cardiac Society of Australia and New Zealand', value: 'csanz' },
    {
        label: 'ANZCTCA - Conjoint Committee for the Recognition of Training in CT Coronary Angiography',
        value: 'anzctca'
    },
    { label: 'RANZCR - Royal Australian and New Zealand College of Radiologists', value: 'ranzcr' },
    { label: 'RACS - Royal Australasian College of Surgeons', value: 'racs' },
    { label: 'Other', value: 'other' }
];

export const careerStageOptions: ComboboxItem[] = [
    { label: 'Specialist', value: 'specialist' },
    { label: 'Fellow', value: 'fellow' },
    { label: 'Trainee', value: 'trainee' },
    { label: 'Junior Doctor', value: 'junior-doctor' },
    { label: 'Non Medical', value: 'non-medical' },
    { label: 'Other', value: 'other' }
];
