import { Table } from '@mantine/core';
import { LesionReportingBias } from '__generated__/graphql';
import { useMemo } from 'react';

const segments = [
    'RCA_Prox',
    'RCA_Mid',
    'RCA_Dist',
    'PDA',
    'PLB',
    'LMA',
    'LAD_Prox',
    'LAD_Mid',
    'LAD_Dist',
    'D1',
    'D2',
    'D3',
    'LCX',
    'OM1',
    'OM2',
    'RCA_Rm'
];

interface Props {
    user: LesionReportingBias[];
    cohort: LesionReportingBias[];
}

const good = ['#1cd500', '#1cd500', '#1cd500'];
const bad = ['#d20000', '#d20000', '#d20000'];

export function PerLesionBias(props: Props) {
    const { user, cohort } = props;

    const d = useMemo(() => {
        const data = {
            tp: [] as number[],
            fp: [] as number[],
            fn: [] as number[],
            tn: [] as number[]
        };

        for (let i = 0; i < segments.length; i++) {
            const row = user.find((u) => u.lesionName === segments[i]);
            data.tp.push(row?.bias.truePositive ?? -1);
            data.fp.push(row?.bias.falsePositive ?? -1);
            data.fn.push(row?.bias.falseNegative ?? -1);
            data.tn.push(row?.bias.trueNegative ?? -1);
        }

        return data;
    }, [user]);

    const max = {
        tp: Math.max(...d.tp),
        fp: Math.max(...d.fp),
        fn: Math.max(...d.fn)
    };

    return (
        <Table withColumnBorders withRowBorders>
            <Table.Thead>
                <Table.Tr>
                    <Table.Td />
                    {segments.map((s) => (
                        <Table.Td ta="center" w={`${100 / segments.length}%`}>
                            {s.replace('_', ' ')}
                        </Table.Td>
                    ))}
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody ta="center">
                <Table.Tr>
                    <Table.Td>True Positive</Table.Td>
                    {d.tp.map((val) => (
                        <Table.Td>{val}</Table.Td>
                    ))}
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>False Positive</Table.Td>
                    {d.fp.map((val) => (
                        <Table.Td>{val}</Table.Td>
                    ))}
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>False Negative</Table.Td>
                    {d.fn.map((val) => (
                        <Table.Td>{val}</Table.Td>
                    ))}
                </Table.Tr>
                <Table.Tr>
                    <Table.Td>True Negative</Table.Td>
                    {d.tn.map((val) => (
                        <Table.Td>{val}</Table.Td>
                    ))}
                </Table.Tr>
            </Table.Tbody>
        </Table>
    );
}
