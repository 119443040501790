import { LogbookDataForAllSubmissionsQuery } from '__generated__/graphql';
import { differenceInSeconds } from 'date-fns';

export const REVIEW_MINUTES_PER_CASE = 15;

export const calcHoursBetween = (startTime: string, endTime: string) =>
    differenceInSeconds(new Date(Number(endTime)), new Date(Number(startTime))) / 60 / 60;

export const calcTotalCat1Hours = (studySubmissions: LogbookDataForAllSubmissionsQuery['studySubmissions']) =>
    studySubmissions
        ?.map((ss) => (ss?.startTime && ss?.endTime ? calcHoursBetween(ss.startTime, ss.endTime) : 0))
        .reduce((acc, cur) => acc + cur, 0);

export const calcTotalCat2Hours = (studySubmissions: LogbookDataForAllSubmissionsQuery['studySubmissions']) =>
    studySubmissions?.map(() => REVIEW_MINUTES_PER_CASE / 60).reduce((acc, cur) => acc + cur, 0);
