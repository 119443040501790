import { useQuery } from '@apollo/client';
import { Divider, Table, Text } from '@mantine/core';
import { ProductFamily } from '__generated__/graphql';
import { GET_SELF } from 'graphql/queries';

const ProductMap: Record<ProductFamily, string> = {
    CARDIAC_CT: 'Cardiac CT',
    BREAST_MRI: 'Breast MRI'
};

export function UserProfileSubscriptions() {
    const { data, loading, error } = useQuery(GET_SELF, {
        fetchPolicy: 'network-only'
    });

    const subs = data?.me?.productSubscriptions;

    return (
        <div>
            <Text size="lg" mb="lg">
                Your subscriptions
                <Divider />
            </Text>
            <Table>
                <Table.Thead>
                    <Table.Tr fw="bold">
                        <Table.Td>Product</Table.Td>
                        <Table.Td>Start Date</Table.Td>
                        <Table.Td>End Date</Table.Td>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {subs?.map((s) => (
                        <Table.Tr>
                            <Table.Td>{ProductMap[s.product]}</Table.Td>
                            <Table.Td>{new Date(s.startDate).toLocaleDateString()}</Table.Td>
                            <Table.Td>{new Date(s.endDate).toLocaleDateString()}</Table.Td>
                        </Table.Tr>
                    ))}
                    {/* This is an abomination */}
                    {/* {Object.keys(ProductMap)
                        .filter((p) => !subs?.map((s) => s.product as string).includes(p))
                        .map((p) => (
                            <Table.Tr>
                                <Table.Td>{ProductMap[p as ProductFamily]}</Table.Td>
                                <Table.Td>Not yet subscribed</Table.Td>
                            </Table.Tr>
                        ))} */}
                </Table.Tbody>
            </Table>
        </div>
    );
}
