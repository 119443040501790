import { css } from '@emotion/react';
import routes from 'config/routes';
import { Link } from 'react-router-dom';

export function AllCasesCompleted() {
    return (
        <div
            css={css`
                width: 100%;
                height: 100%;
                min-height: 20rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;
                font-size: 1.25rem;
            `}
        >
            <div>Congratulations!</div>
            <div>You have completed all cases available on The Cardiac CT Course.</div>
            <span>
                Go to the <Link to={routes.AUTHED.LOGBOOK_PAGE.url}>Logbook Page</Link> to export your traning record.
            </span>
        </div>
    );
}
