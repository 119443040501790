import { css } from '@emotion/react';
import { CorrectCheck } from './correct-check';
import { IncorrectCross } from './incorrect-cross';

interface Props {
    children: React.ReactNode;
    showCorrect?: boolean;
    isCorrect?: boolean;
    error?: boolean;
    errorText?: string;
}

export function QuestionHeading(props: Props) {
    const { children, showCorrect, isCorrect, error, errorText } = props;

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                gap: 4px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <h5
                    css={css`
                        font-size: 16px;
                        margin: 0.5rem 0;
                    `}
                >
                    {children}
                </h5>
                {showCorrect && (isCorrect ? <CorrectCheck /> : <IncorrectCross />)}
            </div>
            {error && (
                <div
                    css={css`
                        color: red;
                        margin-top: -0.7rem;
                        font-size: 0.9rem;
                    `}
                >
                    {errorText ?? 'Please fix the errors with this question.'}
                </div>
            )}
        </div>
    );
}
