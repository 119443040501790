import { css } from '@emotion/react';
import { Card, Select, SimpleGrid, Text } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { Schema } from './schema';
import { useEffect } from 'react';

const jurisdictions = [
    { value: 'aunz', label: 'Australia / New Zealand' },
    { value: 'usa', label: 'United States of America' },
    { value: 'uk', label: 'United Kingdom' },
    { value: 'eu', label: 'Europe' },
    { value: 'other', label: 'Other' }
];

const yearsReporting = [
    { value: 'lt1', label: 'Less than 1 year' },
    { value: '1-3', label: '1-3 years' },
    { value: '4-5', label: '4-5 years' },
    { value: 'mt5', label: 'More than 5 years' }
];

const ctsReported = [
    { value: 'lt10', label: 'Less than 10' },
    { value: '1049', label: '10 - 49' },
    { value: '5099', label: '50 - 99' },
    { value: 'mt100', label: '100 or more' }
];

export function OnboardingStepTwo() {
    const { watch, setValue, formState, register } = useFormContext<Schema>();

    useEffect(() => {
        register('professionalHistory');
        setValue('professionalHistory', {
            jurisdiction: '',
            yearsReporting: '',
            numCts: '',
            specialty: ''
        });
    }, [register, setValue]);

    return (
        <div
            css={css`
                width: min(90%, 28rem);
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 auto;
            `}
        >
            <h2>Professional Information</h2>
            <Card withBorder w="100%">
                <SimpleGrid cols={1} spacing="lg">
                    <Select
                        label="Accreditation Jurisdiction"
                        placeholder="Australia / New Zealand"
                        data={jurisdictions}
                        value={watch('professionalHistory.jurisdiction')}
                        onChange={(val) =>
                            setValue('professionalHistory.jurisdiction', val ?? '', { shouldValidate: true })
                        }
                        error={formState.errors.professionalHistory?.jurisdiction?.message}
                    />
                    <Select
                        label="Years reporting Cardiac CT"
                        placeholder="1-3 years"
                        data={yearsReporting}
                        value={watch('professionalHistory.yearsReporting')}
                        onChange={(val) =>
                            setValue('professionalHistory.yearsReporting', val ?? '', { shouldValidate: true })
                        }
                        error={formState.errors.professionalHistory?.yearsReporting?.message}
                    />
                    <Select
                        label="Number of CT studies reported"
                        placeholder="50"
                        data={ctsReported}
                        value={watch('professionalHistory.numCts')}
                        onChange={(val) => setValue('professionalHistory.numCts', val ?? '', { shouldValidate: true })}
                        error={formState.errors.professionalHistory?.numCts?.message}
                    />
                    <Select
                        label="Specialty"
                        placeholder="Cardiology"
                        data={[
                            { label: 'Cardiology', value: 'cardiology' },
                            { label: 'Radiology', value: 'radiology' },
                            { label: 'Other', value: 'other' }
                        ]}
                        value={watch('professionalHistory.specialty')}
                        onChange={(val) =>
                            setValue('professionalHistory.specialty', val ?? '', { shouldValidate: true })
                        }
                        error={formState.errors.professionalHistory?.specialty?.message}
                    />
                </SimpleGrid>
            </Card>
        </div>
    );
}
