import { motion } from 'framer-motion';
import { css } from '@emotion/react';

type Coords = {
    x: number;
    y: number;
};

type Props = {
    initialPos: Coords;
    targetPos: Coords;
    onRestCallback?: () => void;
};

export default function CompletionBadge(props: Props) {
    const { initialPos, targetPos, onRestCallback } = props;

    return (
        <motion.div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                height: 1.75em;
                width: 1.75em;
                background-color: red;
                color: white;
                font-weight: bold;
                border-radius: 100px;
                position: absolute;
                z-index: 999999;
            `}
            initial={{
                x: initialPos.x,
                y: initialPos.y
            }}
            animate={{
                x: targetPos.x,
                y: targetPos.y
            }}
            transition={{
                type: 'spring',
                stiffness: 100,
                damping: 18
            }}
            onAnimationComplete={onRestCallback}
        >
            +1
        </motion.div>
    );
}
