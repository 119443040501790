import { useQuery } from '@apollo/client';
import { GET_SELF } from 'graphql/queries';
import { useEffect } from 'react';
import { useGlobalStore } from 'store/zustand/store';

const POLL_INTERVAL_MS = 500;

export function useCheckSubscription() {
    const [showModal, setShowModal] = useGlobalStore((state) => [
        state.showSubscriptionModal,
        state.setShowSubscriptionModal
    ]);
    const { data, startPolling, stopPolling, error } = useQuery(GET_SELF, {
        fetchPolicy: 'network-only'
    });

    useEffect(() => {
        startPolling(POLL_INTERVAL_MS);

        if (data?.me) {
            stopPolling();

            if (data.me.productSubscriptions?.length === 0) {
                setShowModal(true);
            }
        }

        return () => {
            stopPolling();
        };
    }, [data?.me, setShowModal, startPolling, stopPolling]);

    useEffect(() => {
        if (error) {
            startPolling(POLL_INTERVAL_MS);
        }
    }, [error, startPolling]);

    const loading = !data?.me;

    return { showModal, loading };
}
