import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';
import routes from 'config/routes';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AspectRatio from '@mui/icons-material/AspectRatio';
import Timeline from '@mui/icons-material/Timeline';
import ContactSupport from '@mui/icons-material/ContactSupport';
import Logo from 'assets/images/cctc-logo.png';
import LogoDarkMode from 'assets/images/cctc-logo-dark.png';
import Book from '@mui/icons-material/Book';
import LastPage from '@mui/icons-material/LastPage';
import PowerSetting from '@mui/icons-material/PowerSettingsNew';
import FirstPage from '@mui/icons-material/FirstPage';
import { useGlobalStore } from 'store/zustand/store';
import { supabase } from 'index';
import { css } from '@emotion/react';
import { Menu, Tooltip } from '@mantine/core';

type NavProps = {
    navOpen: boolean;
};

interface MenuItemProps {
    name: string;
    children: React.ReactNode;
    dest: string;
    navOpen: boolean;
}

const MenuItem = ({ name, children, dest, navOpen }: MenuItemProps) => (
    <ListItem component={NavLink} button to={dest} exact>
        <ListItemIcon>
            <Tooltip label={name} disabled={navOpen} position="right">
                {children}
            </Tooltip>
        </ListItemIcon>
        {navOpen && <ListItemText primary={name} />}
    </ListItem>
);

const SideNav: React.FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const [navOpen, setLogbookRef, setNavOpen] = useGlobalStore((state) => [
        state.navOpen,
        state.setLogbookRef,
        state.setNavOpen
    ]);

    useEffect(() => {
        if (ref?.current) {
            setLogbookRef(ref.current);
        }
    }, [setLogbookRef]);

    return (
        <StyledList navOpen={navOpen}>
            <StyledLogo src={navOpen ? Logo : LogoDarkMode} />
            <Divider />
            <MenuItem name="Profile" navOpen={navOpen} dest={routes.AUTHED.USER_PROFILE_PAGE.url}>
                <AccountCircle />
            </MenuItem>
            <MenuItem name="Logbook" navOpen={navOpen} dest={routes.AUTHED.LOGBOOK_PAGE.url}>
                <Book />
            </MenuItem>
            <ListItem component={NavLink} button to={routes.AUTHED.STUDY_PAGE.url} exact>
                <ListItemIcon ref={ref}>
                    <Tooltip label="Studies" disabled={navOpen} position="right">
                        <AspectRatio />
                    </Tooltip>
                </ListItemIcon>
                {navOpen && <ListItemText primary="Studies" />}
            </ListItem>
            <MenuItem name="Progress" navOpen={navOpen} dest={routes.AUTHED.USER_PROGRESS_PAGE.url}>
                <Timeline />
            </MenuItem>
            <Divider />
            <MenuItem name="Support" navOpen={navOpen} dest={routes.AUTHED.SUPPORT_PAGE.url}>
                <ContactSupport />
            </MenuItem>
            <Divider />
            <ListItem
                component={NavLink}
                button
                to={routes.PUBLIC.HOME_PAGE.url}
                exact
                onClick={() => supabase.auth.signOut()}
            >
                <ListItemIcon>
                    <Tooltip label="Log Out" disabled={navOpen} position="right">
                        <PowerSetting />
                    </Tooltip>
                </ListItemIcon>
                {navOpen && <ListItemText primary="Log Out" />}
            </ListItem>
            {history.location.pathname.includes(routes.AUTHED.STUDY_PAGE.url) && (
                <ListItem
                    onClick={() => setNavOpen(!navOpen)}
                    css={css`
                        margin-top: 2rem;
                    `}
                    button
                >
                    {navOpen ? (
                        <FirstPage />
                    ) : (
                        <Tooltip label="Open Menu" disabled={navOpen} position="right">
                            <LastPage />
                        </Tooltip>
                    )}
                    {navOpen && (
                        <ListItemText
                            css={css`
                                margin-left: 2rem;
                            `}
                            primary="Close Menu"
                        />
                    )}
                </ListItem>
            )}
        </StyledList>
    );
};

export default SideNav;

const StyledList = styled(List)<NavProps>`
    width: ${(p) => (p.navOpen ? p.theme.sideNav.width : p.theme.sideNav.collapsedWidth)};
    height: 100vh;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background-color: white;
    z-index: 199;

    && {
        padding: 1em;
        position: fixed;
    }

    .MuiListItemIcon-root {
        min-width: ${(p) => !p.navOpen && '48px'};
    }

    background-color: ${(p) => !p.navOpen && 'black'};
    color: ${(p) => !p.navOpen && '#d6d6d6'};
    svg {
        stroke: ${(p) => !p.navOpen && '#d6d6d6'};
    }

    svg {
        &:hover {
            stroke: ${(p) => !p.navOpen && '#e0435d'};
            fill: ${(p) => !p.navOpen && '#e0435d'};
        }
    }
`;

const StyledLogo = styled.img`
    width: 100%;
    padding: 1em;
    padding-bottom: 2em;
`;
