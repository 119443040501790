import LogbookTable from './components/LogbookTable';
import { exportLogbook } from 'utils/logbook-utils';
import CtaButton from 'components/_new/Button';
import { useQuery } from '@apollo/client';
import { LOGBOOK_DATA_ALL_SUBMISSIONS } from 'graphql/queries';
import { css } from '@emotion/react';
import { LoadingOverlay, Skeleton } from '@mantine/core';

export default function LogbookPage() {
    const { data, loading } = useQuery(LOGBOOK_DATA_ALL_SUBMISSIONS, { fetchPolicy: 'network-only' });

    return (
        <div>
            <LoadingOverlay visible={loading} />
            <div>
                The platform tracks how much time you spend inside the education modules.
                <br />
                Using these records, we can automatically generate a logbook for you to use as a reference when
                registering your CPD activities.
            </div>
            <div
                css={css`
                    margin-top: 3rem;
                `}
            >
                {!!data ? (
                    <>
                        <CtaButton onClick={() => exportLogbook(data)}>Export as Excel Sheet</CtaButton>
                        <LogbookTable data={data} />
                    </>
                ) : (
                    <>
                        <Skeleton height="6rem" width="14rem" m="sm" />
                        <Skeleton height={16} w="80%" m="sm" radius="xl" />
                        <Skeleton height={16} w="80%" m="sm" radius="xl" />
                        <Skeleton height={16} w="80%" m="sm" radius="xl" />
                        <Skeleton height={16} w="80%" m="sm" radius="xl" />
                        <Skeleton height={16} w="80%" m="sm" radius="xl" />
                    </>
                )}
            </div>
        </div>
    );
}
