import { SimpleGrid, Group, TextInput, Radio, Card, SelectProps, Select, ComboboxItem } from '@mantine/core';
import { OtherContainer } from './other-container';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { Schema } from './schema';
import { useEffect } from 'react';
import { collegeOptions, careerStageOptions, specialtyOptions } from '../UserProfilePage/data';

interface OnboardingCmeSelectProps extends SelectProps {
    fieldName: Exclude<keyof Schema['cme'], 'register' | 'collegeId'>;
}

const OnboardingCmeSelect = (props: OnboardingCmeSelectProps) => {
    const { disabled, fieldName } = props;
    const { register, watch, setValue, formState, trigger } = useFormContext<Schema>();
    const presetName = `cme.${fieldName}.preset` as Parameters<typeof setValue>[0];
    const manualName = `cme.${fieldName}.manual` as Parameters<typeof setValue>[0];

    console.log('watch', watch());
    console.log('errors', formState.errors);

    const onChange = (val: string | null) => {
        setValue(presetName, val ?? '');
        trigger(presetName);
    };

    return (
        <div
            css={css`
                width: 100%;
            `}
        >
            <Select
                {...props}
                // @ts-expect-error asdasd
                value={watch(presetName)}
                w="100%"
                onChange={onChange}
                error={formState.errors.cme?.[fieldName]?.preset?.message}
            />
            {watch(presetName) === 'other' && (
                <TextInput
                    {...register(manualName)}
                    error={formState.errors.cme?.[fieldName]?.manual?.message}
                    label="Specify Other"
                    disabled={disabled}
                    w="100%"
                    withAsterisk
                />
            )}
        </div>
    );
};

export function OnboardingStepThree() {
    const { watch, setValue, register, formState } = useFormContext<Schema>();

    useEffect(() => {
        register('cme');
        // @ts-expect-error asdasd
        setValue('cme', { register: true });
    }, [register, setValue]);

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0 auto;
            `}
        >
            <h2>CME Registration</h2>
            <Card withBorder w="100%">
                <SimpleGrid cols={2}>
                    <Radio.Group
                        label="Register for CME points?"
                        value={watch('cme.register') ? 'yes' : 'no'}
                        onChange={(val) => setValue('cme.register', val === 'yes')}
                    >
                        <Group gap="lg">
                            <Radio mt="xs" value="yes" label="Yes" />
                            <Radio mt="xs" value="no" label="No" />
                        </Group>
                    </Radio.Group>
                    <div />
                    <OtherContainer
                        _css={css`
                            grid-column: 1 / 3;
                        `}
                    >
                        <OnboardingCmeSelect
                            fieldName="college"
                            label="College"
                            placeholder="Select College"
                            withAsterisk
                            data={collegeOptions}
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <TextInput
                            {...register('cme.collegeId')}
                            label="College ID"
                            placeholder="ABC-123"
                            w="100%"
                            error={formState.errors.cme?.collegeId?.message}
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <OnboardingCmeSelect
                            fieldName="careerStage"
                            label="Career Stage"
                            placeholder="Select Career Stage"
                            withAsterisk
                            data={careerStageOptions}
                        />
                    </OtherContainer>
                    <OtherContainer>
                        <OnboardingCmeSelect
                            fieldName="specialty"
                            label="Specialty"
                            placeholder="Select Specialty"
                            withAsterisk
                            data={specialtyOptions}
                        />
                    </OtherContainer>
                </SimpleGrid>
            </Card>
        </div>
    );
}
