import { QuestionType } from 'pages/Authed/CasePage/components/QuestionsDrawer';
import { FieldValues, UseFormGetFieldState, UseFormRegister, Validate } from 'react-hook-form';

type ManualValidations = 'requireAnswer';

interface RegisterQuestionArgs {
    questionId: string;
    questionType: QuestionType;
    register: UseFormRegister<FieldValues>;
}

const validations: Validate<ManualValidations, FieldValues> | Record<ManualValidations, Validate<any, FieldValues>> = {
    requireAnswer: (val) => 'answer' in val && val.answer !== null && val.answer !== undefined
};

const registerQuestion = ({ questionId, questionType, register }: RegisterQuestionArgs) => {
    register(questionId, {
        value: { questionType },
        validate: { ...validations }
    });
};

interface GetErrorMessageArgs {
    questionId: string;
    getFieldState: UseFormGetFieldState<FieldValues>;
    // path?: string;
}

const getErrorMessage = ({ questionId, getFieldState }: GetErrorMessageArgs): string | undefined => {
    const state = getFieldState(questionId);
    const error = state.error;

    console.log(questionId, error);

    if (!error) {
        return undefined;
    }

    switch (error.type) {
        case 'requireAnswer':
            return 'Please answer this question.';
    }

    return undefined;
};

export const FormUtils = { registerQuestion, getErrorMessage };
