import React, { useEffect, useLayoutEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SVG from './RCA-LCA-ver5.svg?react';
import './svgstyles.css';
import { SvgAnswer, SvgQuestion } from '__generated__/graphql';
import { Correction } from './correction';
import { css } from '@emotion/react';
import { QuestionHeading } from './question-heading';
import { FormUtils } from './form-utils';
import { Table } from '@mantine/core';

interface Props {
    question: SvgQuestion;
    showCorrect?: boolean;
    overrideSelection?: SvgAnswer['segmentId'][];
}

const querySvg = (segments: string[], svg: Element) =>
    svg.querySelectorAll<SVGPathElement>(segments.map((s) => `[data-name='${s}']`).join(','));
const fillSegments = (segments: ReturnType<typeof querySvg>, colour: string) =>
    segments.forEach((s) => (s.style.fill = colour));

const TableHeaderCell = ({ children, colour }: { children: React.ReactNode; colour: string }) => (
    <Table.Td w="33%">
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                font-weight: bold;
                height: 100%;
            `}
        >
            <div
                css={css`
                    height: 1.5rem;
                    width: 100%;
                    background-color: ${colour};
                    margin-bottom: 8px;
                `}
            />
            {children}
        </div>
    </Table.Td>
);

export default function SVGQuestion(props: Props) {
    const { showCorrect, question, overrideSelection } = props;
    const methods = useFormContext();
    const { register, watch, setValue, formState, getFieldState, trigger } = methods;
    const { text, correctSegments } = question;
    const disabled = formState.isSubmitting || showCorrect;
    const questionId = `SvgQuestion.q-${question.id}`;
    const selectedSegments: string[] | undefined = watch(`${questionId}.answer`);

    console.log('SVGQuestion', { showCorrect, question, overrideSelection }, watch(questionId));

    useEffect(() => {
        FormUtils.registerQuestion({
            questionId,
            questionType: 'SvgQuestion',
            register
        });
    }, [questionId, register]);

    useEffect(() => {
        if (!!overrideSelection) {
            setValue(`${questionId}.answer`, overrideSelection);
        }
    }, [overrideSelection, questionId, setValue]);

    useLayoutEffect(() => {
        const svg = document.querySelector('#rca-and-lca');

        if (!svg || !selectedSegments?.length) {
            return;
        }

        querySvg(selectedSegments, svg).forEach((p) => p.classList.add('selected'));
    }, []);

    useLayoutEffect(() => {
        const svg = document.querySelector('#rca-and-lca');
        if (!showCorrect || !svg) {
            return;
        }

        const all = svg.querySelectorAll('path');
        all.forEach((el) => el.classList.add('disabled'));
        all.forEach((el) => el.classList.remove('selected'));
        all.forEach((el) => (el.style.fill = ''));
        console.log('allthings', all);

        console.log('seg', selectedSegments, correctSegments);

        // true positve - user selected stenosis = GREEN
        const userSelectedAndCorrectQuery = selectedSegments?.filter((s) => correctSegments?.includes(s));
        if (userSelectedAndCorrectQuery?.length) {
            const paths = querySvg(userSelectedAndCorrectQuery, svg);
            console.log('paths', paths, userSelectedAndCorrectQuery);
            // fillSegments(paths, '#20b82f');
            paths.forEach((p) => p.classList.add('true-positive'));
        }

        // false positive - user selected non-stenosis = ORANGE
        const userSelectedAndIncorrectQuery = selectedSegments?.filter((s) => !correctSegments?.includes(s));
        if (userSelectedAndIncorrectQuery?.length) {
            // fillSegments(querySvg(userSelectedAndIncorrectQuery, svg), '#ffb41f');
            querySvg(userSelectedAndIncorrectQuery, svg).forEach((p) => p.classList.add('false-positive'));
        }

        console.log({ userSelectedAndCorrectQuery, userSelectedAndIncorrectQuery });

        // false negatve - user didn't select stenosis = RED
        const userNotSelectedAndCorrectQuery = correctSegments?.filter((s) => !selectedSegments?.includes(s));
        if (userNotSelectedAndCorrectQuery?.length) {
            // fillSegments(querySvg(userNotSelectedAndCorrectQuery, svg), '#cd0707');
            querySvg(userNotSelectedAndCorrectQuery, svg).forEach((p) => p.classList.add('false-negative'));
        }

        return () => {
            all.forEach((el) => el.classList.remove('disabled'));
            all.forEach((el) => el.classList.remove('true-positive'));
            all.forEach((el) => el.classList.remove('false-positive'));
            all.forEach((el) => el.classList.remove('false-negative'));
        };
    }, [correctSegments, selectedSegments, showCorrect]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as SVGElement;
        const segmentName = target.attributes.getNamedItem('data-name')?.value;

        console.log('svg click', segmentName);

        if (!segmentName || target.classList.contains('no-click')) {
            return;
        }

        const alreadySelected = selectedSegments?.includes(segmentName);
        let selected;

        if (alreadySelected) {
            selected = selectedSegments?.filter((seg) => seg !== segmentName);
            target.classList.remove('selected');
        } else {
            selected = [...(selectedSegments ?? []), segmentName];
            target.classList.add('selected');
        }

        setValue(`${questionId}.answer`, selected, { shouldValidate: true });
    };

    const allTruePositivesSelected = correctSegments?.every((s) => selectedSegments?.includes(s));
    const maxOneFalsePositiveSelected =
        (selectedSegments?.filter((s) => !correctSegments?.includes(s)).length ?? 0) <= 1;
    const noFalseNegatives = correctSegments?.filter((s) => !selectedSegments?.includes(s)).length === 0;
    const isCorrect = allTruePositivesSelected && maxOneFalsePositiveSelected && noFalseNegatives;

    return (
        <div>
            <QuestionHeading
                showCorrect={showCorrect}
                isCorrect={isCorrect}
                error={!!getFieldState(questionId).error}
                errorText={FormUtils.getErrorMessage({ questionId, getFieldState })}
            >
                {text}
            </QuestionHeading>
            <div
                onClick={handleClick}
                css={css`
                    pointer-events: ${disabled ? 'none' : 'all'};
                    user-select: ${disabled ? 'none' : 'all'};
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        .disabled {
                            fill: #b2b2b2;
                        }

                        .true-positive {
                            fill: #20b82f;
                        }

                        .false-negative {
                            fill: #cd0707;
                        }

                        .false-positive {
                            fill: #ffb41f;
                        }

                        path {
                            fill: #f45858;
                        }

                        path:hover {
                            fill: #5a42ff;
                            cursor: pointer;
                        }

                        .selected {
                            fill: #20b82f;
                        }

                        .no-click {
                            fill: black;
                        }

                        .no-click:hover {
                            fill: black;
                            cursor: not-allowed;
                        }
                    }
                `}
            >
                {disabled && (
                    <div
                        css={css`
                            position: absolute;
                            background: #000000;
                            height: 100%;
                            width: 100%;
                            opacity: 0.3;
                        `}
                    ></div>
                )}
                <SVG
                    css={css`
                        padding: 1rem;
                        max-width: 100%;
                    `}
                />
            </div>
            {showCorrect && (
                <Correction>
                    <div>
                        <Table
                            css={css`
                                text-align: center;
                            `}
                            withColumnBorders
                            withRowBorders
                            withTableBorder
                        >
                            <Table.Thead>
                                <Table.Tr>
                                    <TableHeaderCell colour="#20b82f">
                                        Stenoses correctly identified
                                        <br />
                                        (True Positive)
                                    </TableHeaderCell>
                                    <TableHeaderCell colour="#cd0707">
                                        Missed stenoses
                                        <br />
                                        (False Negative)
                                        <br />
                                    </TableHeaderCell>
                                    <TableHeaderCell colour="#ffb41f">
                                        Erroneously identified stenoses
                                        <br />
                                        (False Positive)
                                    </TableHeaderCell>
                                </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td>
                                        {selectedSegments
                                            ?.filter((s) => correctSegments?.includes(s))
                                            .map((s) => s.replace('_', ' '))
                                            .join(', ')}
                                    </Table.Td>
                                    <Table.Td>
                                        {correctSegments
                                            ?.filter((s) => !selectedSegments?.includes(s))
                                            .map((s) => s.replace('_', ' '))
                                            .join(', ')}
                                    </Table.Td>
                                    <Table.Td>
                                        {selectedSegments
                                            ?.filter((s) => !correctSegments?.includes(s))
                                            .map((s) => s.replace('_', ' '))
                                            .join(', ')}
                                    </Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                        <div
                            css={css`
                                margin-top: 1rem;
                            `}
                        >
                            {/* Assessment Critera: */}
                            <ul
                                css={css`
                                    /* margin-top: 4px; */
                                `}
                            >
                                {showCorrect && !allTruePositivesSelected && (
                                    <li>
                                        {/* Must select all moderate and severe stenoses */}
                                        {showCorrect && !allTruePositivesSelected && (
                                            <div
                                                css={css`
                                                    color: #cd0707;
                                                    font-style: italic;
                                                `}
                                            >
                                                At least one stenosis was missed
                                            </div>
                                        )}
                                    </li>
                                )}
                                {showCorrect && !maxOneFalsePositiveSelected && (
                                    <li>
                                        Must not select more than one area that is not a moderate or severe stenosis
                                        {showCorrect && !maxOneFalsePositiveSelected && (
                                            <div
                                                css={css`
                                                    color: #cd0707;
                                                    font-style: italic;
                                                `}
                                            >
                                                More than one area incorrectly identified as a stenosis
                                            </div>
                                        )}
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </Correction>
            )}
        </div>
    );
}
