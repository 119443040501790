import posthog from 'posthog-js';

interface Flag extends Record<string, string> {
    name: string;
}

export const FeatureFlag = {
    QuestionsDrawerUX: {
        name: 'questions-drawer-ux',
        DEFAULT: 'default',
        SPLIT_SCREEN: 'split-screen',
        SCROLL: 'scroll'
    },
    ShowProgressFilters: {
        name: 'show-progress-filters'
    }
} satisfies Record<string, Flag>;

// posthog.featureFlags.override({ [FeatureFlag.QuestionsDrawerUX.name]: FeatureFlag.QuestionsDrawerUX.SCROLL });

export const getFeatureFlag = (flag: keyof typeof FeatureFlag) => posthog.getFeatureFlag(FeatureFlag[flag].name);
