import create from 'zustand';

interface GlobalStore {
    currentUserId: string | null;
    setCurrentUserId: (currentUserId: string | null) => void;
    navOpen: boolean;
    setNavOpen: (navOpen: boolean) => void;
    questionDrawerOpen: boolean;
    setQuestionDrawerOpen: (questionDrawerOpen: boolean) => void;
    logbookRef: HTMLDivElement | null;
    setLogbookRef: (ref: HTMLDivElement) => void;
    showSubscriptionModal: boolean;
    setShowSubscriptionModal: (showSubscritpionModal: boolean) => void;
    publicNavOpen: boolean;
    setPublicNavOpen: (publicNavOpen: boolean) => void;
}

export const useGlobalStore = create<GlobalStore>((set) => ({
    currentUserId: null,
    setCurrentUserId: (currentUserId) => set({ currentUserId }),
    navOpen: true,
    setNavOpen: (navOpen) => set({ navOpen }),
    questionDrawerOpen: true,
    setQuestionDrawerOpen: (questionDrawerOpen) => set({ questionDrawerOpen }),
    logbookRef: null,
    setLogbookRef: (logbookRef) => set({ logbookRef }),
    showSubscriptionModal: false,
    setShowSubscriptionModal: (showSubscriptionModal) => set({ showSubscriptionModal }),
    publicNavOpen: false,
    setPublicNavOpen: (publicNavOpen) => set({ publicNavOpen })
}));

// @ts-expect-error global
window.useGlobalStore = useGlobalStore;
