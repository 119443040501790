import { CpdData, LogbookDataForAllSubmissionsQuery } from '__generated__/graphql';
import * as ExcelJS from 'exceljs';
import {
    calcHoursBetween,
    REVIEW_MINUTES_PER_CASE,
    calcTotalCat1Hours,
    calcTotalCat2Hours
} from 'pages/Authed/LogbookPage/utils';

export const exportLogbook = async (data: LogbookDataForAllSubmissionsQuery) => {
    const wb = new ExcelJS.Workbook();
    wb.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ];

    const ws = wb.addWorksheet('Sheet 1');

    ws.addRow([
        'Exam ID',
        'Date',
        'Live',
        'Library',
        'Live Cases only: Case from CT course?',
        'DLP',
        'Correlated',
        'Non-coronary cardiac findings',
        'Non-cardiac findings',
        // 'Unique Episode Number',
        'Facility',
        'Reporting Doctor',
        'Name of Supervising Specialist',
        'Cardiac CT Course Submission ID',
        'Category 1 CPD Hours - Educational Activities',
        'Category 2 CPD Hours - Reviewing Performance and Reflecting on Practice'
    ]);

    if (!data.studySubmissions?.length) {
        return;
    }

    ws.addRows(
        data.studySubmissions.map((s) => {
            const { createdAt } = s;
            const cpd: Partial<CpdData> = { ...s.study.cpdData };
            delete cpd.__typename;
            delete cpd.id;

            return [
                s.study.id,
                new Date(Number(createdAt)).toLocaleDateString(),
                cpd.live,
                cpd.library,
                cpd.fromCTCourse,
                cpd.dlp,
                cpd.correlated,
                cpd.nonCoronaryCardiacFindings,
                cpd.nonCardiacFindings,
                cpd.facility,
                cpd.reportingDoctor,
                cpd.supervisingSpecialist,
                s.id,
                Number(calcHoursBetween(s.startTime, s.endTime).toFixed(2)),
                Number((REVIEW_MINUTES_PER_CASE / 60).toFixed(2))
            ];
        })
    );
    ws.addRow([]);
    ws.addRow([
        ...new Array(12).fill(''),
        'Total CPD Hours',
        Number(calcTotalCat1Hours(data.studySubmissions)?.toFixed(2)),
        Number(calcTotalCat2Hours(data.studySubmissions)?.toFixed(2))
    ]);

    const buf = await wb.xlsx.writeBuffer();
    const a = document.createElement('a');
    const d = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(d);
    a.href = url;
    a.download = 'TheCardiacCTCourse-Logbook.xlsx';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
    }, 0);
};
