import { css } from '@emotion/react';
import React from 'react';

interface Props {
    title?: string;
    children: React.ReactNode;
}

export function QuestionContainer(props: Props) {
    const { title, children } = props;

    return (
        <div
            css={css`
                border: 1px solid #9e9e9e;
                border-radius: 4px;
                padding: 0 0.5rem;
                padding-bottom: 0.5rem;
            `}
        >
            {!!title && (
                <div
                    css={css`
                        font-weight: bold;
                        font-size: 1.2rem;
                    `}
                >
                    {title}
                </div>
            )}
            {children}
        </div>
    );
}
