import { LoadingOverlay, Modal, ModalProps, Text, Textarea } from '@mantine/core';
import { SubmitButton } from '../CasePage/components/QuestionsDrawer/submit-button';
import { css } from '@emotion/react';
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { ADD_STUDY_FEEDBACK } from 'graphql/mutations';

interface Props extends ModalProps {
    studyId: number;
}

export function StudyFeedbackModal(props: Props) {
    const [content, setContent] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [addStudyFeeback] = useMutation(ADD_STUDY_FEEDBACK);
    const { studyId, ...rest } = props;

    const onChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(ev.target.value);
    };

    const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();

        if (content.length) {
            setLoading(true);
            try {
                await addStudyFeeback({
                    variables: {
                        input: {
                            studyId,
                            content
                        }
                    }
                });
            } catch (error) {
                console.log('error submitting feedback');
                console.log(error);
            }
        }

        setSubmitted(true);
        setLoading(false);
    };

    return (
        <Modal {...rest} title="Let us know your thoughts on the training program or this particular study.">
            <LoadingOverlay visible={loading} />
            {submitted ? (
                <Text>Thankyou for your feedback.</Text>
            ) : (
                <form
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        gap: 1rem;
                    `}
                    onSubmit={onSubmit}
                >
                    <Textarea w="100%" minRows={3} autosize value={content} onChange={onChange} maxLength={1000} />
                    <div
                        css={css`
                            margin-top: -16px;
                            font-size: 0.75rem;
                            color: #a8a8a8;
                        `}
                    >
                        {content.length} / 1000
                    </div>
                    <SubmitButton
                        css={css`
                            width: fit-content;
                        `}
                        type="submit"
                    >
                        Submit
                    </SubmitButton>
                </form>
            )}
        </Modal>
    );
}
